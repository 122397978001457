import React from 'react';

import useActivePlayersByUserId from '../hooks/useActivePlayersByUserId';
import useCurrentTurnProperty from '../hooks/useCurrentTurnProperty';
import useCurrentTurnRef from '../hooks/useCurrentTurnRef';
import useUserId from '../hooks/useUserId';
import { Response } from '../shared/types';
import { getLabelForResponse } from '../utils';
import ActionChallengeResponse from './ActionChallengeResponse';
import ActionResolution from './ActionResolution';
import Counteraction from './Counteraction';
import Flexbox from './Flexbox';
import Message from './Message';
import MessageButton from './MessageButton';
import MessageContainer from './MessageContainer';
import MessagePrompt from './MessagePrompt';
import PendingMessage from './PendingMessage';

type Props = {
  hasCounteractions: boolean;
};

const ActionResponse: React.FC<Props> = ({ hasCounteractions }) => {
  // Firebase data
  const userId = useUserId();
  const actionResponseByUserId =
    useCurrentTurnProperty('actionResponseByUserId') || {};
  const currentTurnPlayerUserId = useCurrentTurnProperty('playerUserId');
  const activePlayersByUserId = useActivePlayersByUserId();

  // Firebase refs
  const currentTurnRef = useCurrentTurnRef();

  // Derrived data
  const isCurrentTurnPlayer = currentTurnPlayerUserId === userId;
  const hasChallenge = Object.values(actionResponseByUserId).includes(
    Response.Challenge,
  );
  const hasResponded = Boolean(
    actionResponseByUserId[userId] || !activePlayersByUserId[userId],
  );
  const hasPassed = Object.keys(activePlayersByUserId)
    .map(
      (userId) =>
        userId === currentTurnPlayerUserId ||
        actionResponseByUserId[userId] === Response.Pass,
    )
    .reduce((a, b) => a && b, true);

  return (
    <>
      <Flexbox columnGap={8}>
        <MessagePrompt>Challenge the action?</MessagePrompt>
        {Object.entries(actionResponseByUserId).map(([userId, response]) => (
          <MessageContainer key={userId} userId={userId}>
            <Message>{getLabelForResponse(response)}</Message>
          </MessageContainer>
        ))}
        {!hasChallenge &&
          Object.keys(activePlayersByUserId)
            .filter(
              (thisUserId) =>
                thisUserId !== userId &&
                thisUserId !== currentTurnPlayerUserId &&
                !actionResponseByUserId[thisUserId],
            )
            .map((userId) => (
              <MessageContainer key={userId} userId={userId}>
                <PendingMessage />
              </MessageContainer>
            ))}
        {!isCurrentTurnPlayer && !hasResponded && !hasChallenge && (
          <MessageContainer userId={userId}>
            <MessageButton
              onClick={() =>
                currentTurnRef
                  .child('actionResponseByUserId')
                  .child(userId)
                  .set(Response.Pass)
              }>
              {getLabelForResponse(Response.Pass)}
            </MessageButton>
            <MessageButton
              onClick={() =>
                currentTurnRef
                  .child('actionResponseByUserId')
                  .child(userId)
                  .set(Response.Challenge)
              }>
              {getLabelForResponse(Response.Challenge)}
            </MessageButton>
          </MessageContainer>
        )}
      </Flexbox>
      {hasChallenge ? (
        <ActionChallengeResponse hasCounteractions={hasCounteractions} />
      ) : (
        hasPassed &&
        (hasCounteractions ? <Counteraction /> : <ActionResolution />)
      )}
    </>
  );
};

export default ActionResponse;
