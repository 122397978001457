import React from 'react';
import styled, { keyframes } from 'styled-components';

import { Color } from '../constants';
import Flexbox from './Flexbox';
const PendingAvatar = () => (
  <Dots
    alignItemsHorizontal="center"
    alignItemsVertical="center"
    flexDirection="row"
    height={36}
    width={36}
    rowGap={2}>
    <Dot />
    <Dot />
    <Dot />
  </Dots>
);

export default PendingAvatar;

const pulse = keyframes`
  0% {
    opacity: 1;
    transform: scale(1);
  }

  50% {
    opacity: 0.3;
    transform: scale(0.75);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
`;

const Dot = styled(Flexbox)`
  animation: ${pulse} 1s infinite;
  background-color: ${Color.DisabledText};
  border-radius: 50%;
  counter-increment: dot;
  height: 8px;
  width: 8px;
`;

const Dots = styled(Flexbox)`
  & ${Dot}:nth-child(1) {
    animation-delay: 0s;
  }
  & ${Dot}:nth-child(2) {
    animation-delay: 0.25s;
  }
  & ${Dot}:nth-child(3) {
    animation-delay: 0.5s;
  }
`;
