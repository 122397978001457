import React from 'react';
import styled from 'styled-components';

import { Spacing } from '../constants';
import { Character } from '../shared/types';
import {
  getAssetForCharacter,
  getColorForCharacter,
  getLabelForCharacter,
} from '../utils';
import BodyText from './BodyText';
import Flexbox from './Flexbox';

type Props = {
  character?: Character;
};

const CharacterName: React.FC<Props> = ({ character }) =>
  character ? (
    <Flexbox
      alignItemsVertical="center"
      flexDirection="row"
      rowGap={Spacing.XSmall}>
      <Icon character={character} />
      <BodyText style={{ color: getColorForCharacter(character) }}>
        {getLabelForCharacter(character)}
      </BodyText>
    </Flexbox>
  ) : null;

export default CharacterName;

const Icon = styled(Flexbox)<{ character: Character }>`
  ${({ character }) => ({
    maskImage: `url(${getAssetForCharacter(character)})`,
  })}
  background-color: ${({ character }) => getColorForCharacter(character)};
  height: 32px;
  mask-repeat: no-repeat;
  mask-size: 100%;
  width: 32px;
`;
