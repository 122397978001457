import { useDatabaseObjectData } from 'reactfire';

import { Player } from '../shared/firebaseTypes';
import useGameRef from './useGameRef';

const usePlayerProperty = <K extends keyof Player>(userId: string, key: K) =>
  useDatabaseObjectData<Player[K]>(
    useGameRef().child('playersByUserId').child(userId).child(key),
  );

export default usePlayerProperty;
