import React from 'react';
import styled from 'styled-components';

import { Color } from '../constants';
import usePlayerProperty from '../hooks/usePlayerProperty';
import { Card as CardType } from '../shared/firebaseTypes';
import { Character } from '../shared/types';
import { getColorForCharacter } from '../utils';
import Flexbox from './Flexbox';

type Props = {
  userId: string;
  isVisible?: boolean;
};

const PlayerInfluenceCards: React.FC<Props> = ({ isVisible, userId }) => {
  const influenceCards = (usePlayerProperty(userId, 'influenceCards') ||
    {}) as { [key: string]: CardType };
  return (
    <>
      {Object.entries(influenceCards).map(([key, card]) => (
        <Card key={key} isVisible={isVisible} {...card} />
      ))}
    </>
  );
};

export default PlayerInfluenceCards;

const Card = styled(Flexbox)<CardType & { isVisible?: boolean }>`
  ${({ character, isRevealed, isVisible }) => ({
    backgroundColor:
      isRevealed || isVisible
        ? getColorForCharacter(character as Character)
        : Color.WashDeemphasized,
  })}
  border-radius: 4px;
  height: 30px;
  width: 20px;
  &::after {
    ${({ isRevealed }) => (isRevealed ? { opacity: 1 } : { opacity: 0 })}
    background: red;
    border-radius: 1px;
    box-shadow: 0 0 0 2px ${Color.App};
    content: '';
    height: 2px;
    left: 0;
    margin: -1px 0 0 -30%;
    position: absolute;
    top: 50%;
    transform: rotate(45deg);
    width: 160%;
  }
`;
