import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';

import { Spacing } from '../constants';
import useActivePlayersByUserId from '../hooks/useActivePlayersByUserId';
import useCurrentTurnProperty from '../hooks/useCurrentTurnProperty';
import useCurrentTurnRef from '../hooks/useCurrentTurnRef';
import useGameProperty from '../hooks/useGameProperty';
import usePlayerProperty from '../hooks/usePlayerProperty';
import usePlayerRef from '../hooks/usePlayerRef';
import useUserId from '../hooks/useUserId';
import { ActionType } from '../shared/types';
import {
  getCharacterForActionType,
  getColorForActionType,
  getCounteractionTypesForActionType,
  getDescriptionForActionType,
  getHasTargetForActionType,
  getHasValue,
  getIsCharacterActionType,
  getLabelForActionType,
} from '../utils';
import ActionMessage from './ActionMessage';
import ActionResolution from './ActionResolution';
import ActionResponse from './ActionResponse';
import BodyText from './BodyText';
import CharacterName from './CharacterName';
import Counteraction from './Counteraction';
import Flexbox from './Flexbox';
import HeadlineText from './HeadlineText';
import HelpButton from './HelpButton';
import Message from './Message';
import MessageButton from './MessageButton';
import MessageContainer from './MessageContainer';
import MetaText from './MetaText';
import Modal from './Modal';
import PendingMessage from './PendingMessage';
import PlayerName from './PlayerName';

const Action: React.FC = () => {
  // Firebase data
  const userId = useUserId();
  const action = useCurrentTurnProperty('action');
  const activePlayersByUserId = useActivePlayersByUserId();
  const coins = usePlayerProperty(userId, 'coins') as number;
  const currentTurnPlayerUserId = useCurrentTurnProperty('playerUserId');
  const hasAllegiances = useGameProperty('hasAllegiances');
  const hasInquisitor = useGameProperty('hasInquisitor');

  // Firebase refs
  const currentTurnPlayerRef = usePlayerRef(currentTurnPlayerUserId);
  const currentTurnRef = useCurrentTurnRef();

  // Local state
  const [draftActionType, setDraftActionType] = useState<ActionType | void>();
  const [isHelpVisible, setIsHelpVisible] = useState(false);

  const hasAction = getHasValue(action);
  const isCharacterAction = getIsCharacterActionType(action?.type);
  const hasCounteractions =
    getCounteractionTypesForActionType(action?.type).length > 0;
  if (hasAction) {
    return (
      <>
        <MessageContainer key="action-message" userId={currentTurnPlayerUserId}>
          <Message
            backgroundColor={getColorForActionType(action?.type as ActionType)}>
            <ActionMessage
              actionType={action?.type as ActionType}
              currentTurnUserId={currentTurnPlayerUserId}
              targetUserId={action?.targetUserId}
            />
          </Message>
        </MessageContainer>
        {isCharacterAction ? (
          <ActionResponse hasCounteractions={hasCounteractions} />
        ) : hasCounteractions ? (
          <Counteraction />
        ) : (
          <ActionResolution />
        )}
      </>
    );
  }

  const isCurrentTurnPlayer = currentTurnPlayerUserId === userId;
  if (isCurrentTurnPlayer && draftActionType) {
    return (
      <MessageContainer key="action-target" userId={currentTurnPlayerUserId}>
        <Flexbox flexDirection="row" width="100%">
          <Message backgroundColor={getColorForActionType(draftActionType)}>
            <ActionMessage
              actionType={draftActionType}
              currentTurnUserId={currentTurnPlayerUserId}
            />
          </Message>
        </Flexbox>
        <Flexbox flexDirection="row" rowGap={Spacing.Small}>
          {Object.keys(activePlayersByUserId)
            .filter((userId) => userId !== currentTurnPlayerUserId)
            .map((targetUserId) => (
              <MessageButton
                key={targetUserId}
                onClick={() => {
                  const action = {
                    type: draftActionType,
                    targetUserId,
                  };
                  currentTurnRef
                    .child('action')
                    .update(action)
                    .then(() => {
                      setDraftActionType();
                    });
                  if (draftActionType === ActionType.Assassinate) {
                    currentTurnPlayerRef.child('coins').set(coins - 3);
                  }
                }}>
                <PlayerName userId={targetUserId} />
              </MessageButton>
            ))}
        </Flexbox>
      </MessageContainer>
    );
  }

  if (isCurrentTurnPlayer && !draftActionType) {
    return (
      <>
        <AnimatePresence>
          {isHelpVisible && (
            <Modal>
              <Flexbox
                isAbsoluteFill
                onClick={() => setIsHelpVisible(false)}
                style={{ overflowY: 'scroll' }}>
                <Flexbox columnGap={Spacing.Large} padding={Spacing.Large}>
                  <Flexbox columnGap={Spacing.Medium}>
                    <Flexbox columnGap={Spacing.Small}>
                      <HeadlineText>Available Actions</HeadlineText>
                      <BodyText>
                        Actions you are able to take this turn.
                      </BodyText>
                    </Flexbox>
                    {[
                      ActionType.Income,
                      ActionType.ForeignAid,
                      coins >= 7 && ActionType.Coup,
                      hasAllegiances && coins >= 2 && ActionType.Conversion,
                      hasAllegiances && ActionType.Embezzlement,
                      coins >= 3 && ActionType.Assassinate,
                      ActionType.Exchange,
                      hasInquisitor && ActionType.Examine,
                      ActionType.Steal,
                      ActionType.Tax,
                    ]
                      .filter((actionType): actionType is ActionType =>
                        Boolean(actionType),
                      )
                      .map((type) => (
                        <Flexbox
                          alignItemsHorizontal="flex-start"
                          columnGap={Spacing.Small}>
                          <Flexbox flexDirection="row" rowGap={Spacing.Small}>
                            <MessageButton
                              key={type}
                              backgroundColor={getColorForActionType(type)}
                              style={{ pointerEvents: 'none' }}>
                              {getLabelForActionType(type)}
                            </MessageButton>
                            <CharacterName
                              character={getCharacterForActionType(
                                type,
                                hasInquisitor,
                              )}
                            />
                          </Flexbox>
                          <MetaText padding={`0 ${Spacing.Medium}px`}>
                            {getDescriptionForActionType(type)}
                          </MetaText>
                        </Flexbox>
                      ))}
                  </Flexbox>
                  <Flexbox columnGap={Spacing.Medium}>
                    <Flexbox columnGap={Spacing.Small}>
                      <HeadlineText>Other Actions</HeadlineText>
                      <BodyText>Actions you aren’t able to take yet.</BodyText>
                    </Flexbox>
                    {[
                      coins < 7 && ActionType.Coup,
                      hasAllegiances && coins < 2 && ActionType.Conversion,
                      coins < 3 && ActionType.Assassinate,
                    ]
                      .filter((actionType): actionType is ActionType =>
                        Boolean(actionType),
                      )
                      .map((type) => (
                        <Flexbox
                          alignItemsHorizontal="flex-start"
                          columnGap={Spacing.Small}>
                          <Flexbox flexDirection="row" rowGap={Spacing.Small}>
                            <MessageButton
                              key={type}
                              backgroundColor={getColorForActionType(type)}
                              style={{ pointerEvents: 'none' }}>
                              {getLabelForActionType(type)}
                            </MessageButton>
                            <CharacterName
                              character={getCharacterForActionType(
                                type,
                                hasInquisitor,
                              )}
                            />
                          </Flexbox>
                          <MetaText padding={`0 ${Spacing.Medium}px`}>
                            {getDescriptionForActionType(type)}
                          </MetaText>
                        </Flexbox>
                      ))}
                  </Flexbox>
                </Flexbox>
              </Flexbox>
            </Modal>
          )}
        </AnimatePresence>
        <MessageContainer key="action" userId={currentTurnPlayerUserId}>
          <HelpButton onClick={() => setIsHelpVisible(true)} />
          {(coins < 10
            ? [
                ActionType.Income,
                ActionType.ForeignAid,
                coins >= 7 && ActionType.Coup,
                hasAllegiances && coins >= 2 && ActionType.Conversion,
                hasAllegiances && ActionType.Embezzlement,
                coins >= 3 && ActionType.Assassinate,
                ActionType.Exchange,
                hasInquisitor && ActionType.Examine,
                ActionType.Steal,
                ActionType.Tax,
              ]
            : [ActionType.Coup]
          )
            .filter((actionType): actionType is ActionType =>
              Boolean(actionType),
            )
            .map((type) => (
              <MessageButton
                key={type}
                backgroundColor={getColorForActionType(type)}
                onClick={() => {
                  const action = { type };
                  if (!getHasTargetForActionType(type)) {
                    currentTurnRef.child('action').update(action);
                  } else {
                    setDraftActionType(type);
                  }
                }}>
                {getLabelForActionType(type)}
              </MessageButton>
            ))}
        </MessageContainer>
      </>
    );
  }

  return (
    <MessageContainer userId={currentTurnPlayerUserId}>
      <PendingMessage />
    </MessageContainer>
  );
};

export default Action;
