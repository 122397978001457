import React, { Suspense, useEffect } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { AuthCheck, useAuth } from 'reactfire';

import { Spacing } from '../constants';
import Flexbox from './Flexbox';
import GameRoute from './GameRoute';
import HomeRoute from './HomeRoute';
import Logo from './Logo';

const App = () => {
  const auth = useAuth();

  useEffect(() => {
    auth.signInAnonymously();
  }, [auth]);

  return (
    <AuthCheck fallback={<div></div>}>
      <Router>
        <Route exact path="/">
          <HomeRoute />
        </Route>
        <Route path="/:gameId">
          <Suspense
            fallback={
              <Flexbox padding={`13px ${Spacing.Medium}px`}>
                <Logo />
              </Flexbox>
            }>
            <GameRoute />
          </Suspense>
        </Route>
      </Router>
    </AuthCheck>
  );
};

export default App;
