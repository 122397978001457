import React from 'react';

import { ActionType } from '../shared/types';
import PlayerName from './PlayerName';

type Props = {
  actionType: ActionType;
  currentTurnUserId: string;
  targetUserId?: string;
};

const ActionMessage: React.FC<Props> = ({
  actionType,
  currentTurnUserId,
  targetUserId,
}) => {
  return (
    <span>
      <strong>
        <PlayerName userId={currentTurnUserId} />
      </strong>{' '}
      {actionType === ActionType.Assassinate && (
        <>
          is trying to assassinate
          {!targetUserId ? (
            '…'
          ) : (
            <strong>
              {' '}
              <PlayerName userId={targetUserId} />
            </strong>
          )}
        </>
      )}
      {actionType === ActionType.Conversion && (
        <>
          converted
          {!targetUserId ? (
            '…'
          ) : (
            <strong>
              {' '}
              <PlayerName userId={targetUserId} />
            </strong>
          )}
        </>
      )}
      {actionType === ActionType.Coup && (
        <>
          performed a coup on
          {!targetUserId ? (
            '…'
          ) : (
            <strong>
              {' '}
              <PlayerName userId={targetUserId} />
            </strong>
          )}
        </>
      )}
      {actionType === ActionType.Embezzlement && (
        <>is trying to embezzle funds from the treasury reserve</>
      )}
      {actionType === ActionType.Examine && (
        <>
          is trying to examine the influence of
          {!targetUserId ? (
            '…'
          ) : (
            <strong>
              {' '}
              <PlayerName userId={targetUserId} />
            </strong>
          )}
        </>
      )}
      {actionType === ActionType.Exchange && (
        <>is trying to exchange influence with the court</>
      )}
      {actionType === ActionType.ForeignAid && (
        <>
          is trying to gain <strong>2 coins</strong> from foreign aid
        </>
      )}
      {actionType === ActionType.Income && (
        <>
          gained <strong>1 coin</strong> of income
        </>
      )}
      {actionType === ActionType.Steal && (
        <>
          is trying to steal from
          {!targetUserId ? (
            '…'
          ) : (
            <strong>
              {' '}
              <PlayerName userId={targetUserId} />
            </strong>
          )}
        </>
      )}
      {actionType === ActionType.Tax && (
        <>
          is trying to gain <strong>3 coins</strong> from taxes
        </>
      )}
    </span>
  );
};

export default ActionMessage;
