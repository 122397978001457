import { useCallback } from 'react';

import { Card, CurrentTurn, Player } from '../shared/firebaseTypes';
import { Allegiance, Character } from '../shared/types';
import { shuffle } from '../utils';
import useGameProperty from './useGameProperty';
import useGameRef from './useGameRef';

const useStartGame = () => {
  const gameRef = useGameRef();
  const currentPlayersByUserId = useGameProperty('playersByUserId');

  const startGame = useCallback(
    (hasAllegiances: boolean = false, hasInquisitor: boolean = false) => {
      const playerCount = Object.keys(currentPlayersByUserId).length;
      const courtDeckCards: Array<Card> = shuffle(
        Object.values(Character)
          .filter(
            (character) =>
              character !==
              (hasInquisitor ? Character.Ambassador : Character.Inquisitor),
          )
          .map((character) =>
            Array(playerCount < 7 ? 3 : playerCount < 9 ? 4 : 5).fill({
              character,
            }),
          )
          .reduce((a, b) => [...a, ...b]),
      );

      let currentTurn: CurrentTurn = { playerUserId: '' };

      const playersByUserId = shuffle(Object.entries(currentPlayersByUserId))
        .map(([userId, player], turnOrder): [string, Player] => {
          if (turnOrder === 0) {
            currentTurn = { playerUserId: userId };
          }
          return [
            userId,
            {
              ...player,
              coins: 2,
              influenceCards: {
                left: courtDeckCards.pop() as Card,
                right: courtDeckCards.pop() as Card,
              },
              turnOrder,
              // @ts-ignore
              allegiance: hasAllegiances
                ? turnOrder % 2
                  ? Allegiance.Loyalist
                  : Allegiance.Reformist
                : null,
            },
          ];
        })
        .reduce(
          (playersByUserId, [userId, player]) => ({
            ...playersByUserId,
            [userId]: player,
          }),
          {},
        );

      gameRef.update({
        hasAllegiances,
        hasInquisitor,
        hasStarted: true,
        courtDeckCards,
        currentTurn,
        playersByUserId,
      });
    },
    [gameRef, currentPlayersByUserId],
  );

  return startGame;
};

export default useStartGame;
