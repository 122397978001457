import { motion } from 'framer-motion';
import React, { useLayoutEffect, useRef } from 'react';
import styled, { CSSObject } from 'styled-components';

import { Color } from '../constants';
import usePlayerProperty from '../hooks/usePlayerProperty';
import useUserId from '../hooks/useUserId';
import Flexbox from './Flexbox';

type Props = {
  className?: string;
  size?: number;
  style?: CSSObject;
  userId: string;
};

const PlayerAvatar: React.FC<Props> = ({
  className,
  size = 36,
  style,
  userId,
}) => {
  const ownUserId = useUserId();
  const emoji = usePlayerProperty(userId, 'emoji');
  const ref = useRef<HTMLCanvasElement | null>(null);

  useLayoutEffect(() => {
    const canvas = ref.current;
    if (canvas) {
      const context = canvas.getContext('2d');
      if (context) {
        context.clearRect(0, 0, 512, 512);
        context.font = '360px Arial';
        context.textAlign = 'center';
        context.textBaseline = 'bottom';
        context.fillText(emoji, 256, 470);
      }
    }
  }, [emoji]);

  return (
    <StyledPlayerAvatar
      className={className}
      isOwnAvatar={ownUserId === userId}
      size={size}
      style={style}>
      <motion.canvas
        height={512}
        ref={ref}
        style={{ width: size, height: size }}
        width={512}
      />
    </StyledPlayerAvatar>
  );
};

const StyledPlayerAvatar = styled(Flexbox)<{
  isOwnAvatar?: boolean;
  size: number;
}>`
  ${({ isOwnAvatar, size }) =>
    isOwnAvatar
      ? {
          boxShadow: `inset 0 0 0 2px ${Color.PrimaryText}`,
          height: size,
          width: size,
        }
      : {
          backgroundColor: Color.WashDeemphasized,
          height: size,
          width: size,
        }}
  align-items: center;
  border-radius: 50%;
  justify-content: center;
`;

export default styled(PlayerAvatar)``;
