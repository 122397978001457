import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Color, Spacing } from '../constants';
import useCreateGameAsync from '../hooks/useCreateGameAsync';
import useJoinGameAsync from '../hooks/useJoinGameAsync';
import Button from './Button';
import Flexbox from './Flexbox';
import HeadlineText from './HeadlineText';
import Input from './Input';
import Logo from './Logo';

const Home: React.FC = () => {
  const [name, setName] = useState('');
  const [inviteCode, setInviteCode] = useState('');
  const createGameAsync = useCreateGameAsync();
  const joinGameAsync = useJoinGameAsync();
  const history = useHistory();
  const [hasNameError, setHasNameError] = useState(false);
  const [hasInviteCodeError, setHasInviteCodeError] = useState(false);
  const [isCreatingGame, setIsCreatingGame] = useState(false);
  const [gameId, setGameId] = useState<string | VoidFunction>();

  return (
    <Flexbox
      isAbsoluteFill
      columnGap={Spacing.Large}
      padding={`13px ${Spacing.Medium}px`}>
      <Flexbox
        columnGap={Spacing.Medium}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}>
        <Logo />
        <AnimatePresence onExitComplete={() => history.push(`/${gameId}`)}>
          {!gameId && (
            <>
              <Flexbox
                exit={{ opacity: 0 }}
                flexDirection="row"
                height={44}
                style={{
                  backgroundColor: Color.WashDeemphasized,
                  borderRadius: 6,
                }}>
                <Flexbox
                  margin={4}
                  width="calc(50% - 8px)"
                  height={36}
                  style={{
                    backgroundColor: Color.App,
                    borderRadius: 4,
                    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                    left: isCreatingGame ? 'auto' : 0,
                    position: 'absolute',
                    right: isCreatingGame ? 0 : 'auto',
                    top: 0,
                  }}
                />
                <HeadlineText
                  alignItemsHorizontal="center"
                  alignItemsVertical="center"
                  isFlexGrow
                  onClick={() => setIsCreatingGame(false)}
                  style={{ opacity: isCreatingGame ? 0.3 : 1 }}>
                  Join game
                </HeadlineText>
                <HeadlineText
                  alignItemsHorizontal="center"
                  alignItemsVertical="center"
                  isFlexGrow
                  onClick={() => setIsCreatingGame(true)}
                  style={{ opacity: isCreatingGame ? 1 : 0.3 }}>
                  Create game
                </HeadlineText>
              </Flexbox>
              <Flexbox
                columnGap={Spacing.Medium}
                exit={{ opacity: 0 }}
                initial={{ translateY: 20 }}
                animate={{ translateY: 0 }}>
                <Flexbox columnGap={Spacing.Small}>
                  <Flexbox
                    animate={
                      isCreatingGame
                        ? {
                            marginBottom: -44,
                            opacity: 0,
                            pointerEvents: 'none',
                          }
                        : {
                            marginBottom: Spacing.Small,
                            opacity: 1,
                            pointerEvents: 'all',
                          }
                    }>
                    <Input
                      hasError={hasInviteCodeError}
                      onChange={(e) => {
                        setHasInviteCodeError(false);
                        e.target.value.length <= 4 &&
                          setInviteCode(e.target.value.toLocaleUpperCase());
                      }}
                      placeholder="4 letter code"
                      value={inviteCode}
                    />
                  </Flexbox>
                  <Input
                    hasError={hasNameError}
                    onChange={(e) => {
                      setHasNameError(false);
                      e.target.value.length <= 12 && setName(e.target.value);
                    }}
                    placeholder="Name"
                    style={{ zIndex: 2 }}
                    value={name}
                  />
                </Flexbox>
                <Button
                  isDisabled={
                    isCreatingGame ? !name : inviteCode.length !== 4 || !name
                  }
                  onClick={
                    isCreatingGame
                      ? () => {
                          setHasNameError(!name);
                          if (name) {
                            createGameAsync({ name }).then(
                              (gameId) => gameId && setGameId(gameId),
                            );
                          }
                        }
                      : () => {
                          setHasNameError(!name);
                          setHasInviteCodeError(inviteCode.length !== 4);
                          if (name && inviteCode.length === 4) {
                            joinGameAsync({ name, inviteCode }).then((gameId) =>
                              gameId
                                ? setGameId(gameId)
                                : setHasInviteCodeError(true),
                            );
                          }
                        }
                  }>
                  <AnimatePresence>
                    {isCreatingGame ? (
                      <HeadlineText
                        alignItemsHorizontal="center"
                        alignItemsVertical="center"
                        key="create"
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        isAbsoluteFill>
                        Create game
                      </HeadlineText>
                    ) : (
                      <HeadlineText
                        alignItemsHorizontal="center"
                        alignItemsVertical="center"
                        key="join"
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        isAbsoluteFill>
                        Join game
                      </HeadlineText>
                    )}
                  </AnimatePresence>
                </Button>
              </Flexbox>
            </>
          )}
        </AnimatePresence>
      </Flexbox>
    </Flexbox>
  );
};

export default Home;
