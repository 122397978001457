import React from 'react';

import { Color, Spacing } from '../constants';
import useActivePlayersByUserId from '../hooks/useActivePlayersByUserId';
import useCurrentTurnProperty from '../hooks/useCurrentTurnProperty';
import useGameProperty from '../hooks/useGameProperty';
import useUserId from '../hooks/useUserId';
import { Player } from '../shared/firebaseTypes';
import BodyText from './BodyText';
import Flexbox from './Flexbox';
import MetaText from './MetaText';
import PlayerAvatar from './PlayerAvatar';
import PlayerCoins from './PlayerCoins';
import PlayerInfluenceCards from './PlayerInfluenceCards';
import PlayerName from './PlayerName';

type Props = {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
};

const TurnOrder: React.FC<Props> = ({ isExpanded, setIsExpanded }) => {
  const hasStarted = useGameProperty('hasStarted') === true;
  const currentTurnPlayerUserId = useCurrentTurnProperty('playerUserId');
  const activePlayerEntries = Object.entries(
    useActivePlayersByUserId(),
  ).sort(([userIdA, playerA], [userIdB, playerB]) =>
    (playerA.turnOrder as number) < (playerB.turnOrder as number) ? -1 : 1,
  );

  if (
    activePlayerEntries.find(([userId]) => userId === currentTurnPlayerUserId)
  ) {
    while (activePlayerEntries[0][0] !== currentTurnPlayerUserId) {
      activePlayerEntries.push(activePlayerEntries.shift() as [string, Player]);
    }
  }

  return (
    <Flexbox
      animate={{}}
      columnGap={8}
      flexDirection={!hasStarted || isExpanded ? 'column' : 'row'}
      onClick={() => hasStarted && setIsExpanded(!isExpanded)}
      rowGap={-8}>
      {activePlayerEntries.map(([userId, player], i) => (
        <Row
          key={userId}
          hasStarted={hasStarted}
          i={i}
          isExpanded={isExpanded}
          userId={userId}
        />
      ))}
    </Flexbox>
  );
};

export default TurnOrder;

const Row: React.FC<{
  hasStarted: boolean;
  i: number;
  isExpanded: boolean;
  userId: string;
}> = ({ hasStarted, isExpanded, i, userId }) => {
  const ownUserId = useUserId();
  return (
    <Flexbox
      animate={{ opacity: 1 }}
      flexDirection="row"
      initial={{ opacity: 0 }}
      isFlexGrow={i === 0 && hasStarted && !isExpanded}
      style={{
        justifyContent: 'space-between',
        width: !hasStarted || isExpanded ? 'auto' : 36,
        zIndex: 10 - i,
      }}>
      <Flexbox
        alignItemsVertical="center"
        flexDirection="row"
        rowGap={Spacing.Small}>
        <Flexbox
          style={{
            backgroundColor: Color.App,
            borderRadius: '50%',
            boxShadow: `0 0 0 2px ${Color.App}`,
          }}>
          <PlayerAvatar userId={userId} />
        </Flexbox>
        <BodyText
          style={{
            opacity: i === 0 || !hasStarted || isExpanded ? 1 : 0,
            width: 100,
          }}>
          <PlayerName userId={userId} />
          {i === 0 && hasStarted ? '’s turn' : ''}
        </BodyText>
      </Flexbox>
      <Flexbox
        alignItemsVertical="center"
        flexDirection="row"
        rowGap={Spacing.Small}
        style={{ opacity: isExpanded ? 1 : 0 }}>
        {hasStarted && userId !== ownUserId ? (
          <>
            <MetaText>
              <PlayerCoins userId={userId} /> coins
            </MetaText>
            <PlayerInfluenceCards userId={userId} />
          </>
        ) : (
          <Flexbox />
        )}
      </Flexbox>
    </Flexbox>
  );
};
