import React from 'react';

import useActivePlayersByUserId from '../hooks/useActivePlayersByUserId';
import useCurrentTurnProperty from '../hooks/useCurrentTurnProperty';
import useCurrentTurnRef from '../hooks/useCurrentTurnRef';
import useUserId from '../hooks/useUserId';
import { CounteractionType, Response } from '../shared/types';
import { getLabelForResponse } from '../utils';
import CounteractionChallengeResponse from './CounteractionChallengeResponse';
import EndTurn from './EndTurn';
import Flexbox from './Flexbox';
import Message from './Message';
import MessageButton from './MessageButton';
import MessageContainer from './MessageContainer';
import MessagePrompt from './MessagePrompt';
import PendingMessage from './PendingMessage';

const CounteractionResponse: React.FC = () => {
  // Firebase data
  const userId = useUserId();
  const activePlayersByUserId = useActivePlayersByUserId();
  const counteractionByUserId = useCurrentTurnProperty('counteractionByUserId');
  const counteractionResponseByUserId = useCurrentTurnProperty(
    'counteractionResponseByUserId',
  ) as { [key: string]: Response };

  // Firebase refs
  const currentTurnRef = useCurrentTurnRef();

  // Derrived data
  const counteractionPlayerUserId = Object.entries(
    counteractionByUserId || {},
  ).find((entry) => entry[1] !== CounteractionType.Pass)?.[0] as string;
  const isCounteractionPlayer = counteractionPlayerUserId === userId;
  const hasResponded = Boolean(
    counteractionResponseByUserId[userId] || !activePlayersByUserId[userId],
  );
  const hasChallenge = Object.values(counteractionResponseByUserId).includes(
    Response.Challenge,
  );
  const hasPassed = Object.keys(activePlayersByUserId)
    .map(
      (userId) =>
        userId === counteractionPlayerUserId ||
        counteractionResponseByUserId[userId] === Response.Pass,
    )
    .reduce((a, b) => a && b, true);

  return (
    <>
      <Flexbox columnGap={8}>
        <MessagePrompt>Challenge the counteraction?</MessagePrompt>
        {Object.entries(counteractionResponseByUserId).map(
          ([userId, response]) => (
            <MessageContainer key={userId} userId={userId}>
              <Message>{getLabelForResponse(response)}</Message>
            </MessageContainer>
          ),
        )}
        {!hasChallenge &&
          Object.keys(activePlayersByUserId)
            .filter(
              (thisUserId) =>
                thisUserId !== userId &&
                thisUserId !== counteractionPlayerUserId &&
                !counteractionResponseByUserId[thisUserId],
            )
            .map((userId) => (
              <MessageContainer key={userId} userId={userId}>
                <PendingMessage />
              </MessageContainer>
            ))}
        {!isCounteractionPlayer && !hasResponded && !hasChallenge && (
          <MessageContainer userId={userId}>
            <MessageButton
              onClick={() =>
                currentTurnRef
                  .child('counteractionResponseByUserId')
                  .child(userId)
                  .set(Response.Pass)
              }>
              {getLabelForResponse(Response.Pass)}
            </MessageButton>
            <MessageButton
              onClick={() =>
                currentTurnRef
                  .child('counteractionResponseByUserId')
                  .child(userId)
                  .set(Response.Challenge)
              }>
              {getLabelForResponse(Response.Challenge)}
            </MessageButton>
          </MessageContainer>
        )}
      </Flexbox>
      {hasChallenge && <CounteractionChallengeResponse />}
      {!hasChallenge && hasPassed && <EndTurn />}
    </>
  );
};

export default CounteractionResponse;
