import { useCallback } from 'react';

import useCurrentTurnProperty from './useCurrentTurnProperty';
import useGameProperty from './useGameProperty';
import useGameRef from './useGameRef';

const useEndTurn = () => {
  const gameRef = useGameRef();
  const playersByUserId = useGameProperty('playersByUserId');
  const currentTurnPlayerUserId = useCurrentTurnProperty('playerUserId');
  return useCallback(() => {
    const activePlayerEntries = Object.entries(playersByUserId)
      .filter(
        ([userId, player]) =>
          !(
            player.influenceCards?.left.isRevealed &&
            player.influenceCards?.right.isRevealed
          ),
      )
      .sort(([userIdA, playerA], [userIdB, playerB]) =>
        (playerA.turnOrder as number) < (playerB.turnOrder as number) ? -1 : 1,
      );
    const currentTurnPlayerEntryIndex = activePlayerEntries.findIndex(
      ([userId]) => userId === currentTurnPlayerUserId,
    );
    const nextTurnPlayerEntryIndex =
      (currentTurnPlayerEntryIndex + 1) % activePlayerEntries.length;
    const nextTurnPlayerId = activePlayerEntries[nextTurnPlayerEntryIndex][0];
    gameRef.child('currentTurn').set({ playerUserId: nextTurnPlayerId });
  }, [gameRef, playersByUserId, currentTurnPlayerUserId]);
};

export default useEndTurn;
