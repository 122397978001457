import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { FirebaseAppProvider } from 'reactfire';
import { createGlobalStyle } from 'styled-components';

import App from './components/App';
import { Color } from './constants';

const GlobalStyle = createGlobalStyle`
body {
  background-color: ${Color.App};
  margin: 0;
  overflow: hidden;
}

* {
  -webkit-tap-highlight-color: transparent;
  font-family: 'Exo', system-ui, sans-serif;
}
`;

const firebaseConfig = JSON.parse(
  decodeURIComponent(process.env.REACT_APP_FIREBASE_CONFIG as string),
);

// @ts-ignore
ReactDOM.createRoot(document.getElementById('app-root')).render(
  <>
    <GlobalStyle />
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <Suspense fallback={<div></div>}>
        <App />
      </Suspense>
    </FirebaseAppProvider>
  </>,
);
