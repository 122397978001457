import { useDatabaseObjectData } from 'reactfire';

import { CurrentTurn } from '../shared/firebaseTypes';
import useGameRef from './useGameRef';

const useCurrentTurnProperty = <K extends keyof CurrentTurn>(key: K) =>
  useDatabaseObjectData<CurrentTurn[K]>(
    useGameRef().child('currentTurn').child(key),
  );

export default useCurrentTurnProperty;
