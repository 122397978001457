import styled from 'styled-components';

import { Color } from '../constants';
import BodyText from './BodyText';

const MetaText = styled(BodyText)`
  color: ${Color.SecondaryText};
`;

export default MetaText;
