import React from 'react';

import useCurrentTurnProperty from '../hooks/useCurrentTurnProperty';
import useCurrentTurnRef from '../hooks/useCurrentTurnRef';
import usePlayerProperty from '../hooks/usePlayerProperty';
import usePlayerRef from '../hooks/usePlayerRef';
import useUserId from '../hooks/useUserId';
import { Card as CardType } from '../shared/firebaseTypes';
import { Character, Response } from '../shared/types';
import { getHasValue, getLabelForCharacter } from '../utils';
import Card from './Card';
import EndTurn from './EndTurn';
import Flexbox from './Flexbox';
import Message from './Message';
import MessageButton from './MessageButton';
import MessageContainer from './MessageContainer';
import PendingMessage from './PendingMessage';
import PlayerName from './PlayerName';

const CounteractionChallengeResolution: React.FC = () => {
  // Firebase data
  const userId = useUserId();
  const counteractionChallengeResolution = useCurrentTurnProperty(
    'counteractionChallengeResolution',
  );
  const counteractionResponseByUserId = useCurrentTurnProperty(
    'counteractionResponseByUserId',
  );
  const influenceCards = usePlayerProperty(userId, 'influenceCards');

  // Firebase refs
  const currentTurnRef = useCurrentTurnRef();
  const playerRef = usePlayerRef(userId);

  // Derrived data
  const challengingPlayerUserId = Object.entries(
    counteractionResponseByUserId || {},
  ).find((entry) => entry[1] === Response.Challenge)?.[0] as string;

  const hasCounteractionChallengeResolution = getHasValue(
    counteractionChallengeResolution,
  );
  if (hasCounteractionChallengeResolution) {
    return (
      <>
        <MessageContainer key="resolved" userId={challengingPlayerUserId}>
          <Flexbox flexDirection="row" width="100%">
            <Message>
              <span>
                <strong>
                  <PlayerName userId={challengingPlayerUserId} />
                </strong>{' '}
                revealed{' '}
                <strong>
                  {getLabelForCharacter(
                    counteractionChallengeResolution as Character,
                  )}
                </strong>
              </span>
            </Message>
          </Flexbox>
          <Card
            character={counteractionChallengeResolution as Character}
            isRevealed
          />
        </MessageContainer>
        <EndTurn />
      </>
    );
  }

  const isChallengingPlayer = challengingPlayerUserId === userId;
  if (isChallengingPlayer) {
    return (
      <MessageContainer key="resolving" userId={userId}>
        <Flexbox flexDirection="row" width="100%">
          <Message>
            <span>
              <strong>
                <PlayerName userId={userId} />
              </strong>{' '}
              revealed…
            </span>
          </Message>
        </Flexbox>
        {Object.entries(influenceCards || {}).map(
          ([key, card]: [string, CardType], i) =>
            !card.isRevealed ? (
              <MessageButton
                key={i}
                onClick={() => {
                  currentTurnRef
                    .child('counteractionChallengeResolution')
                    .set(card.character);
                  playerRef
                    .child('influenceCards')
                    .child(key)
                    .set({
                      ...card,
                      isRevealed: true,
                    });
                }}>
                {getLabelForCharacter(card.character as Character)}
              </MessageButton>
            ) : null,
        )}
      </MessageContainer>
    );
  }

  return (
    <MessageContainer userId={challengingPlayerUserId}>
      <PendingMessage />
    </MessageContainer>
  );
};

export default CounteractionChallengeResolution;
