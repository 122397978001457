import styled from 'styled-components';

import MessageButton from './MessageButton';

const HelpButton = styled(MessageButton).attrs(() => ({ children: '?' }))`
  font-family: system-ui;
  font-size: 20px;
  font-weight: 400;
  height: 36px;
  line-height: 36px;
  padding: 0;
  text-align: center;
  width: 36px;
`;

export default HelpButton;
