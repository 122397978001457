import React from 'react';

import useCurrentTurnProperty from '../hooks/useCurrentTurnProperty';
import useEndTurn from '../hooks/useEndTurn';
import useUserId from '../hooks/useUserId';
import Button from './Button';
import Flexbox from './Flexbox';

const EndTurn: React.FC = () => {
  const currentTurnPlayerUserId = useCurrentTurnProperty('playerUserId');
  const isCurrentTurnPlayer = currentTurnPlayerUserId === useUserId();
  const endTurn = useEndTurn();

  return isCurrentTurnPlayer ? (
    <Flexbox
      animate={{ opacity: 1, translateY: 0 }}
      initial={{ opacity: 0, translateY: 20 }}>
      <Button onClick={endTurn}>End turn</Button>
    </Flexbox>
  ) : null;
};

export default EndTurn;
