import styled from 'styled-components';

import Flexbox from './Flexbox';

const BodyText = styled(Flexbox)`
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  user-select: none;
  & a {
    transition: opacity 0.1s ease-in-out;
    &:active {
      opacity: 0.3;
    }
  }
`;

export default BodyText;
