import { AnimatePresence } from 'framer-motion';
import React, { useState } from 'react';

import { Spacing } from '../constants';
import useActivePlayersByUserId from '../hooks/useActivePlayersByUserId';
import useCurrentTurnProperty from '../hooks/useCurrentTurnProperty';
import useCurrentTurnRef from '../hooks/useCurrentTurnRef';
import useGameProperty from '../hooks/useGameProperty';
import useUserId from '../hooks/useUserId';
import { Player } from '../shared/firebaseTypes';
import { ActionType, CounteractionType } from '../shared/types';
import {
  getCharacterForCounteractionType,
  getColorForCounteractionType,
  getDescriptionForCounteractionType,
  getLabelForCounteractionType,
} from '../utils';
import ActionResolution from './ActionResolution';
import BodyText from './BodyText';
import CharacterName from './CharacterName';
import CounteractionResponse from './CounteractionResponse';
import Flexbox from './Flexbox';
import HeadlineText from './HeadlineText';
import HelpButton from './HelpButton';
import Message from './Message';
import MessageButton from './MessageButton';
import MessageContainer from './MessageContainer';
import MessagePrompt from './MessagePrompt';
import MetaText from './MetaText';
import Modal from './Modal';
import PendingMessage from './PendingMessage';

const Counteraction: React.FC = () => {
  // Firebase data
  const userId = useUserId();
  const action = useCurrentTurnProperty('action');
  const hasInquisitor = useGameProperty('hasInquisitor');
  const counteractionByUserId = useCurrentTurnProperty(
    'counteractionByUserId',
  ) as { [key: string]: CounteractionType };
  const currentTurnPlayerUserId = useCurrentTurnProperty('playerUserId');
  const activePlayersByUserId = useActivePlayersByUserId();

  // Firebase refs
  const currentTurnRef = useCurrentTurnRef();

  // Local state
  const [isHelpVisible, setIsHelpVisible] = useState(false);

  const counteractionPlayersByUserId: {
    [userId: string]: Player;
  } = Object.entries(activePlayersByUserId)
    .filter(
      ([userId]) =>
        action?.type === ActionType.ForeignAid ||
        action?.targetUserId === userId,
    )
    .reduce(
      (counteractionPlayersByUserId, [userId, player]) => ({
        ...counteractionPlayersByUserId,
        [userId]: player,
      }),
      {},
    );
  const isCurrentTurnPlayer = currentTurnPlayerUserId === userId;
  const isTargedPlayer = action?.targetUserId === userId;
  const hasResponded = Boolean(
    counteractionByUserId[userId] || !counteractionPlayersByUserId[userId],
  );
  const hasCounteraction = Object.values(counteractionByUserId).find(
    (counteractionType) => counteractionType !== CounteractionType.Pass,
  );
  const hasPassed =
    !hasCounteraction &&
    Object.keys(counteractionPlayersByUserId)
      .map(
        (userId) =>
          userId === currentTurnPlayerUserId ||
          counteractionByUserId[userId] === CounteractionType.Pass,
      )
      .reduce((a, b) => a && b, true);

  return (
    <>
      {Object.keys(counteractionPlayersByUserId).length > 0 && (
        <Flexbox columnGap={Spacing.Small}>
          <MessagePrompt>Take a counteraction?</MessagePrompt>
          {Object.entries(counteractionByUserId).map(
            ([userId, counteraction]) => (
              <MessageContainer key={userId} userId={userId}>
                <Message
                  backgroundColor={getColorForCounteractionType(counteraction)}>
                  {getLabelForCounteractionType(counteraction)}
                </Message>
              </MessageContainer>
            ),
          )}
          {!hasCounteraction &&
            Object.keys(counteractionPlayersByUserId)
              .filter(
                (thisUserId) =>
                  thisUserId !== userId &&
                  thisUserId !== currentTurnPlayerUserId &&
                  !counteractionByUserId[thisUserId],
              )
              .map((userId) => (
                <MessageContainer key={userId} userId={userId}>
                  <PendingMessage />
                </MessageContainer>
              ))}
          {!isCurrentTurnPlayer && !hasResponded && !hasCounteraction && (
            <>
              <AnimatePresence>
                {isHelpVisible && (
                  <Modal>
                    <Flexbox
                      isAbsoluteFill
                      onClick={() => setIsHelpVisible(false)}
                      style={{ overflowY: 'scroll' }}>
                      <Flexbox
                        columnGap={Spacing.Large}
                        padding={Spacing.Large}>
                        <Flexbox columnGap={Spacing.Medium}>
                          <Flexbox columnGap={Spacing.Small}>
                            <HeadlineText>
                              Available Counteractions
                            </HeadlineText>
                            <BodyText>
                              Counteractions you are able to take this turn.
                            </BodyText>
                          </Flexbox>
                          {[
                            CounteractionType.Pass,
                            !hasInquisitor &&
                              action?.type === ActionType.Steal &&
                              isTargedPlayer &&
                              CounteractionType.AmbassadorBlockStealing,
                            action?.type === ActionType.Steal &&
                              isTargedPlayer &&
                              CounteractionType.CaptainBlockStealing,
                            action?.type === ActionType.ForeignAid &&
                              CounteractionType.BlockForeignAid,
                            action?.type === ActionType.Assassinate &&
                              isTargedPlayer &&
                              CounteractionType.BlockAssassination,
                            hasInquisitor &&
                              action?.type === ActionType.Steal &&
                              isTargedPlayer &&
                              CounteractionType.InquisitorBlockStealing,
                          ]
                            .filter(
                              (
                                counteractionType,
                              ): counteractionType is CounteractionType =>
                                Boolean(counteractionType),
                            )
                            .map((type) => (
                              <Flexbox
                                alignItemsHorizontal="flex-start"
                                columnGap={Spacing.Small}>
                                <Flexbox
                                  flexDirection="row"
                                  rowGap={Spacing.Small}>
                                  <MessageButton
                                    key={type}
                                    backgroundColor={getColorForCounteractionType(
                                      type,
                                    )}
                                    style={{ pointerEvents: 'none' }}>
                                    {getLabelForCounteractionType(type)}
                                  </MessageButton>
                                  <CharacterName
                                    character={getCharacterForCounteractionType(
                                      type,
                                    )}
                                  />
                                </Flexbox>
                                <MetaText padding={`0 ${Spacing.Medium}px`}>
                                  {getDescriptionForCounteractionType(type)}
                                </MetaText>
                              </Flexbox>
                            ))}
                        </Flexbox>
                      </Flexbox>
                    </Flexbox>
                  </Modal>
                )}
              </AnimatePresence>
              <MessageContainer userId={userId}>
                <HelpButton onClick={() => setIsHelpVisible(true)} />
                {[
                  CounteractionType.Pass,
                  !hasInquisitor &&
                    action?.type === ActionType.Steal &&
                    isTargedPlayer &&
                    CounteractionType.AmbassadorBlockStealing,
                  action?.type === ActionType.Steal &&
                    isTargedPlayer &&
                    CounteractionType.CaptainBlockStealing,
                  action?.type === ActionType.ForeignAid &&
                    CounteractionType.BlockForeignAid,
                  action?.type === ActionType.Assassinate &&
                    isTargedPlayer &&
                    CounteractionType.BlockAssassination,
                  hasInquisitor &&
                    action?.type === ActionType.Steal &&
                    isTargedPlayer &&
                    CounteractionType.InquisitorBlockStealing,
                ]
                  .filter(
                    (
                      counteractionType,
                    ): counteractionType is CounteractionType =>
                      Boolean(counteractionType),
                  )
                  .map((counteractionType) => (
                    <MessageButton
                      key={counteractionType}
                      backgroundColor={getColorForCounteractionType(
                        counteractionType,
                      )}
                      onClick={() =>
                        currentTurnRef
                          .child('counteractionByUserId')
                          .child(userId)
                          .set(counteractionType)
                      }>
                      {getLabelForCounteractionType(counteractionType)}
                    </MessageButton>
                  ))}
              </MessageContainer>
            </>
          )}
        </Flexbox>
      )}
      {hasCounteraction && <CounteractionResponse />}
      {hasPassed && <ActionResolution />}
    </>
  );
};

export default Counteraction;
