import React from 'react';
import styled, { keyframes } from 'styled-components';

import { Color } from '../../constants';
import { Character } from '../../shared/types';
import { getAssetForCharacter, getColorForCharacter } from '../../utils';
import Flexbox from '../Flexbox';
import holofoil from './assets/holofoil.png';
import mask from './assets/mask.png';
import texture from './assets/texture.png';

type Props = {
  character: Character;
  isRevealed?: boolean;
};

const Card: React.FC<Props> = (props) => (
  <Flexbox>
    <StyledCard
      animate={{
        boxShadow: `0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802),
          0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
          0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
          0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
          0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
          0px 100px 80px rgba(0, 0, 0, 0.07)`,
      }}
      transition={{ delay: 0.5, duration: 2 }}
      height={150}
      width={100}
      {...props}>
      <Overlay isAbsoluteFill>
        <Texture isAbsoluteFill />
      </Overlay>
      <Overlay
        animate={{ opacity: 1 }}
        initial={{ opacity: 0 }}
        isAbsoluteFill
        transition={{ delay: 0.5, duration: 2 }}>
        <Holofoil isAbsoluteFill />
        <HolofoilSheen isAbsoluteFill />
      </Overlay>
      <Icon {...props} />
    </StyledCard>
    {props.isRevealed && <Cross animate={{}} />}
  </Flexbox>
);

export default Card;

const StyledCard = styled(Flexbox)<Props>`
  ${({ character }) => ({ backgroundColor: getColorForCharacter(character) })}
  align-items: center;
  border-radius: 18px;
  color: white;
  justify-content: center;
  overflow: hidden;
  z-index: 1;
`;

const Overlay = styled(Flexbox)`
  mask-image: url(${mask});
  mask-size: 100%;
  mask-repeat: no-repeat;
  mix-blend-mode: overlay;
`;

const Texture = styled(Flexbox)`
  background-image: url(${texture});
  background-size: 100%;
  background-repeat: no-repeat;
  opacity: 0.5;
`;

const Holofoil = styled(Flexbox)`
  background: linear-gradient(90deg, #ffb800 0%, #ff80cc 50%, #65daff 100%);
  mask-image: url(${holofoil});
  mask-size: 100%;
  mask-repeat: no-repeat;
`;

const sweep = keyframes`
  0% {
    transform: translateX(0);
  }

  50% {
    transform: translateX(-900px);
  }

  100% {
    transform: translateX(0);
  }
`;

const HolofoilSheen = styled(Flexbox)`
  animation: ${sweep} 10s infinite;
  background: linear-gradient(
    135deg,
    #808080 10%,
    rgba(128, 128, 128, 0) 20%,
    #808080 40%,
    #808080 60%,
    rgba(128, 128, 128, 0) 80%,
    #808080 90%
  );
  width: 1000px;
`;

const Icon = styled(Flexbox)<Props>`
  ${({ character }) => ({
    maskImage: `url(${getAssetForCharacter(character)})`,
  })}
  background-color: white;
  height: 72px;
  mask-repeat: no-repeat;
  mask-size: 100%;
  width: 72px;
`;

const Cross = styled(Flexbox)`
  background-color: red;
  border-radius: 2px;
  box-shadow: 0 0 0 2px ${Color.App};
  height: 4px;
  margin: -2px 0 0 -30%;
  position: absolute;
  top: 50%;
  transform: rotate(45deg);
  width: 160%;
  z-index: 2;
`;
