import React from 'react';
import { createPortal } from 'react-dom';
import styled from 'styled-components';

import Flexbox from './Flexbox';

const Modal: React.FC = ({ children }) => {
  return createPortal(
    <StyledModal
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      isAbsoluteFill>
      <Flexbox
        initial={{ opacity: 0, translateY: 20 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{ delay: 0.75 }}
        isFlexGrow>
        {children}
      </Flexbox>
    </StyledModal>,
    document.getElementById('modal-root') as Element,
  );
};

export default Modal;

const StyledModal = styled(Flexbox)`
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(10px);
  z-index: 10000;
`;
