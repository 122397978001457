import React from 'react';
import styled from 'styled-components';

import { Spacing } from '../constants';
import useUserId from '../hooks/useUserId';
import Flexbox from './Flexbox';
import Message from './Message';
import PlayerAvatar from './PlayerAvatar';

type Props = {
  userId: string;
};

const MessageContainer: React.FC<Props> = ({ userId, children }) => {
  const ownUserId = useUserId();
  const alignItemsHorizontal = ownUserId === userId ? 'flex-end' : 'flex-start';
  return (
    <Flexbox>
      <Flexbox
        alignItemsVertical="flex-end"
        animate={{ opacity: 1, translateY: 0 }}
        flexDirection="row"
        initial={{ opacity: 0, translateY: 20 }}>
        {alignItemsHorizontal === 'flex-start' && (
          <PlayerAvatar
            style={{ marginRight: Spacing.Small }}
            userId={userId}
          />
        )}
        <StyledMessageContainer
          alignItemsHorizontal={alignItemsHorizontal}
          columnGap={Spacing.Small}
          isFlexGrow
          isFlexWrap
          flexDirection="row"
          rowGap={Spacing.Small}
          style={{
            marginBottom: -Spacing.Small,
            padding:
              alignItemsHorizontal === 'flex-start' ? '0 10% 0 0' : '0 0 0 10%',
          }}>
          {children}
        </StyledMessageContainer>
        {alignItemsHorizontal === 'flex-end' && (
          <PlayerAvatar userId={userId} />
        )}
      </Flexbox>
    </Flexbox>
  );
};

export default MessageContainer;

const StyledMessageContainer = styled(Flexbox)`
  & ${Message} {
    ${({ alignItemsHorizontal }) =>
      alignItemsHorizontal === 'flex-start'
        ? { borderRadius: '22px 22px 22px 6px' }
        : { borderRadius: '22px 22px 6px 22px' }}
  }
  & > ${Flexbox} {
    justify-content: ${({ alignItemsHorizontal }) => alignItemsHorizontal};
  }
`;
