import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useDatabase } from 'reactfire';

const useGameRef = () => {
  const database = useDatabase();
  const { gameId } = useParams();
  return useMemo(() => database.ref('gamesById').child(gameId as string), [
    database,
    gameId,
  ]);
};

export default useGameRef;
