import React from 'react';

import useCurrentTurnProperty from '../hooks/useCurrentTurnProperty';
import useCurrentTurnRef from '../hooks/useCurrentTurnRef';
import useDrawCards from '../hooks/useDrawCards';
import usePlayerProperty from '../hooks/usePlayerProperty';
import usePlayerRef from '../hooks/usePlayerRef';
import useReturnCards from '../hooks/useReturnCards';
import useUserId from '../hooks/useUserId';
import { Card as CardType } from '../shared/firebaseTypes';
import { Character, CounteractionType } from '../shared/types';
import {
  getCharacterHasCounteractionType,
  getHasValue,
  getLabelForCharacter,
} from '../utils';
import ActionResolution from './ActionResolution';
import Card from './Card';
import CounteractionChallengeResolution from './CounteractionChallengeResolution';
import Flexbox from './Flexbox';
import Message from './Message';
import MessageButton from './MessageButton';
import MessageContainer from './MessageContainer';
import PendingMessage from './PendingMessage';
import PlayerName from './PlayerName';

const CounteractionChallengeResponse: React.FC = () => {
  // Firebase data
  const userId = useUserId();
  const counteractionByUserId = useCurrentTurnProperty(
    'counteractionByUserId',
  ) as { [userId: string]: CounteractionType };
  const counteractionChallengeResponse = useCurrentTurnProperty(
    'counteractionChallengeResponse',
  );
  const influenceCards = usePlayerProperty(userId, 'influenceCards');

  // Firebase refs
  const currentTurnRef = useCurrentTurnRef();
  const playerRef = usePlayerRef(userId);

  // Firebase actions
  const drawCards = useDrawCards();
  const returnCards = useReturnCards();

  // Derrived data
  const counteractionPlayerUserId = Object.entries(counteractionByUserId).find(
    (entry) => entry[1] !== CounteractionType.Pass,
  )?.[0] as string;
  const counteractionType = Object.values(counteractionByUserId).find(
    (counteractionType) => counteractionType !== CounteractionType.Pass,
  ) as CounteractionType;
  const characterHasCounteractionType = getCharacterHasCounteractionType(
    counteractionChallengeResponse as Character,
    counteractionType,
  );

  const hasCounteractionChallengeResponse = getHasValue(
    counteractionChallengeResponse,
  );
  if (hasCounteractionChallengeResponse) {
    return (
      <>
        <MessageContainer key="responded" userId={counteractionPlayerUserId}>
          <Flexbox flexDirection="row" width="100%">
            <Message>
              <span>
                <strong>
                  <PlayerName userId={counteractionPlayerUserId} />
                </strong>{' '}
                revealed{' '}
                <strong>
                  {getLabelForCharacter(
                    counteractionChallengeResponse as Character,
                  )}
                </strong>
              </span>
            </Message>
          </Flexbox>
          <Card
            character={counteractionChallengeResponse as Character}
            isRevealed={!characterHasCounteractionType}
          />
        </MessageContainer>
        {characterHasCounteractionType ? (
          <CounteractionChallengeResolution />
        ) : (
          <ActionResolution />
        )}
      </>
    );
  }

  const isCounteractionPlayer = counteractionPlayerUserId === userId;
  if (isCounteractionPlayer) {
    return (
      <MessageContainer key="responding" userId={userId}>
        <Flexbox flexDirection="row" width="100%">
          <Message>
            <span>
              <strong>
                <PlayerName userId={userId} />
              </strong>{' '}
              revealed…
            </span>
          </Message>
        </Flexbox>
        {Object.entries(influenceCards || {}).map(
          ([key, card]: [string, CardType], i) =>
            !card.isRevealed ? (
              <MessageButton
                key={i}
                onClick={() => {
                  currentTurnRef
                    .child('counteractionChallengeResponse')
                    .set(card.character);
                  if (
                    getCharacterHasCounteractionType(
                      card.character as Character,
                      counteractionType,
                    )
                  ) {
                    returnCards([card])
                      .then(() => drawCards(1))
                      .then(([card]) =>
                        playerRef.child('influenceCards').child(key).set(card),
                      );
                  } else {
                    playerRef
                      .child('influenceCards')
                      .child(key)
                      .set({ ...card, isRevealed: true });
                  }
                }}>
                {getLabelForCharacter(card.character as Character)}
              </MessageButton>
            ) : null,
        )}
      </MessageContainer>
    );
  }

  return (
    <MessageContainer userId={counteractionPlayerUserId}>
      <PendingMessage />
    </MessageContainer>
  );
};

export default CounteractionChallengeResponse;
