import styled from 'styled-components';

import MetaText from './MetaText';

const MessagePrompt = styled(MetaText).attrs(() => ({
  animate: { opacity: 1, translateY: 0 },
  initial: { opacity: 0, translateY: 20 },
}))`
  text-align: center;
`;

export default MessagePrompt;
