import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import {
  GetGameInviteCodeRequest,
  GetGameInviteCodeResponse,
} from '../shared/functionTypes';
import useFirebaseFunctionAsync from './useFirebaseFunctionAsync';

const useGameInviteCode = () => {
  const { gameId } = useParams();

  const getGameInviteCode = useFirebaseFunctionAsync<
    GetGameInviteCodeRequest,
    GetGameInviteCodeResponse
  >('getGameInviteCode');

  const [inviteCode, setInviteCode] = useState<string | null>(null);

  useEffect(() => {
    getGameInviteCode({ gameId: gameId as string }).then(setInviteCode);
  }, [gameId, getGameInviteCode]);

  return inviteCode;
};

export default useGameInviteCode;
