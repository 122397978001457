import React from 'react';

import usePlayerProperty from '../hooks/usePlayerProperty';

type Props = {
  userId: string;
};

const PlayerName: React.FC<Props> = ({ userId }) => (
  <>{usePlayerProperty(userId, 'name')}</>
);

export default PlayerName;
