import styled from 'styled-components';

import { absoluteFill, Color, Spacing } from '../constants';
import HeadlineText from './HeadlineText';

type Props = {
  backgroundColor?: string;
};

const MessageButton = styled(HeadlineText).attrs(() => ({
  whileTap: { scale: 0.95 },
}))<Props>`
  ${({ backgroundColor }) =>
    backgroundColor
      ? { backgroundColor, color: Color.WhiteText }
      : { backgroundColor: Color.WashDeemphasized }}
  border-radius: 22px;
  overflow: hidden;
  padding: ${Spacing.Small}px ${Spacing.Medium}px;
  &::after {
    ${absoluteFill}
    background-color: rgba(0, 0, 0, 0.15);
    content: '';
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.1s ease-in-out;
  }
  &:active {
    &::after {
      opacity: 1;
    }
  }
`;

export default MessageButton;
