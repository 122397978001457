import { CSSObject } from 'styled-components';

export enum Color {
  Ambassador = '#A5D41D',
  Assassin = '#393640',
  Captain = '#2EC0FF',
  Contessa = '#FF551F',
  Duke = '#F447BA',
  Inquisitor = '#FFB800',
  PrimaryText = 'rgba(0, 0, 0, 1)',
  SecondaryText = 'rgba(0, 0, 0, 0.3)',
  DisabledText = 'rgba(0, 0, 0, 0.15)',
  WhiteText = '#FFFFFF',
  App = '#FCFCFF',
  Wash = 'rgba(0, 0, 0, 0.15)',
  WashDeemphasized = 'rgba(0, 0, 0, 0.075)',
  Divider = 'rgba(0, 0, 0, 0.15)',
}

export enum Spacing {
  XSmall = 4,
  Small = 8,
  Medium = 16,
  Large = 32,
}

export const absoluteFill: CSSObject = {
  height: '100%',
  left: 0,
  position: 'absolute',
  top: 0,
  width: '100%',
};
