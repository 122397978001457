import { useMemo } from 'react';

import useGameRef from './useGameRef';

const useCurrentTurnRef = () => {
  const gameRef = useGameRef();
  return useMemo(() => gameRef.child('currentTurn'), [gameRef]);
};

export default useCurrentTurnRef;
