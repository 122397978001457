import styled from 'styled-components';

import { Color, Spacing } from '../constants';

type Props = {
  hasError?: boolean;
};

const Input = styled.input<Props>`
  ${({ hasError }) =>
    hasError
      ? { boxShadow: 'inset 0 0 0 1px red, 0 0 0 3px rgba(255, 0, 0, 0.15)' }
      : { boxShadow: `inset 0 0 0 1px ${Color.Divider}` }}
  appearance: none;
  background-color: ${Color.App};
  border-radius: 6px;
  border: none;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: 500;
  height: 44px;
  line-height: 20px;
  outline: none;
  padding: 12px ${Spacing.Small}px;
  transition: box-shadow 0.1s ease-in-out;
  &::placeholder {
    color: ${Color.SecondaryText};
    line-height: 24px;
  }
`;

export default Input;
