import { useCallback } from 'react';

import { Card } from '../shared/firebaseTypes';
import { shuffle } from '../utils';
import useGameProperty from './useGameProperty';
import useGameRef from './useGameRef';

const useReturnCards = () => {
  const courtDeckCards = Object.values(useGameProperty('courtDeckCards') || {});
  const gameRef = useGameRef();

  const returnCards = useCallback(
    (cards: Array<Card>) => {
      return gameRef
        .child('courtDeckCards')
        .set(
          shuffle([
            ...courtDeckCards,
            ...cards.map(({ character }) => ({ character })),
          ]),
        );
    },
    [courtDeckCards, gameRef],
  );

  return returnCards;
};

export default useReturnCards;
