import ambassador from './assets/ambassador.png';
import assassin from './assets/assassin.png';
import captain from './assets/captain.png';
import contessa from './assets/contessa.png';
import duke from './assets/duke.png';
import inquisitor from './assets/inquisitor.png';
import { Color } from './constants';
import {
  ActionType,
  Character,
  CounteractionType,
  Response,
} from './shared/types';

export const getHasValue = (value: any) =>
  value !== null && typeof value === 'object'
    ? Object.keys(value).length > 0
    : Boolean(value);

export const shuffle = <T>(array: Array<T>): Array<T> => {
  let unshuffledItems = array.length;
  while (unshuffledItems) {
    const randomIndex = Math.floor(Math.random() * unshuffledItems--);
    const temporaryItem = array[unshuffledItems];
    array[unshuffledItems] = array[randomIndex];
    array[randomIndex] = temporaryItem;
  }
  return array;
};

export const getIsCharacterActionType = (actionType?: string) =>
  typeof actionType === 'string' &&
  [
    ActionType.Assassinate,
    ActionType.Embezzlement,
    ActionType.Examine,
    ActionType.Exchange,
    ActionType.Steal,
    ActionType.Tax,
  ].includes(actionType as ActionType);

export const getHasTargetForActionType = (actionType?: string) =>
  typeof actionType === 'string' &&
  [
    ActionType.Assassinate,
    ActionType.Conversion,
    ActionType.Coup,
    ActionType.Examine,
    ActionType.Steal,
  ].includes(actionType as ActionType);

export const getCounteractionTypesForActionType = (
  actionType?: string,
): Array<CounteractionType> => {
  switch (actionType) {
    case ActionType.Assassinate:
      return [CounteractionType.BlockAssassination];
    case ActionType.ForeignAid:
      return [CounteractionType.BlockForeignAid];
    case ActionType.Steal:
      return [
        CounteractionType.AmbassadorBlockStealing,
        CounteractionType.CaptainBlockStealing,
        CounteractionType.InquisitorBlockStealing,
      ];
    default:
      return [];
  }
};

export const getCharacterForActionType = (
  actionType: string,
  hasInquisitor?: boolean,
): Character | undefined => {
  switch (actionType) {
    case ActionType.Assassinate:
      return Character.Assassin;
    case ActionType.Examine:
      return Character.Inquisitor;
    case ActionType.Exchange:
      return hasInquisitor ? Character.Inquisitor : Character.Ambassador;
    case ActionType.Steal:
      return Character.Captain;
    case ActionType.Tax:
      return Character.Duke;
  }
};

export const getNeedsResolutionForActionType = (actionType?: ActionType) =>
  typeof actionType === 'string' &&
  [
    ActionType.Assassinate,
    ActionType.Coup,
    ActionType.Examine,
    ActionType.Exchange,
  ].includes(actionType);

export const getCharacterHasActionType = (
  character: Character,
  actionType: ActionType,
) =>
  (({
    [Character.Ambassador]: [ActionType.Exchange],
    [Character.Assassin]: [ActionType.Assassinate],
    [Character.Captain]: [ActionType.Steal],
    [Character.Contessa]: [],
    [Character.Duke]: [ActionType.Tax],
    [Character.Inquisitor]: [ActionType.Exchange, ActionType.Examine],
  } as { [key: string]: Array<ActionType> })[character]?.includes(actionType));

export const getCharacterHasCounteractionType = (
  character: Character,
  counteractionType: CounteractionType,
) =>
  (({
    [Character.Ambassador]: [CounteractionType.AmbassadorBlockStealing],
    [Character.Assassin]: [],
    [Character.Captain]: [CounteractionType.CaptainBlockStealing],
    [Character.Contessa]: [CounteractionType.BlockAssassination],
    [Character.Duke]: [CounteractionType.BlockForeignAid],
    [Character.Inquisitor]: [CounteractionType.InquisitorBlockStealing],
  } as { [key: string]: Array<CounteractionType> })[character]?.includes(
    counteractionType,
  ));

export const getLabelForResponse = (response: string) =>
  (({
    [Response.Pass]: 'Pass',
    [Response.Challenge]: 'Challenge',
  } as { [key: string]: string })[response]);

export const getLabelForActionType = (actionType: ActionType) =>
  (({
    [ActionType.Assassinate]: 'Assassinate',
    [ActionType.Conversion]: 'Conversion',
    [ActionType.Coup]: 'Coup',
    [ActionType.Embezzlement]: 'Embezzlement',
    [ActionType.Examine]: 'Examine',
    [ActionType.Exchange]: 'Exchange',
    [ActionType.ForeignAid]: 'Foreign Aid',
    [ActionType.Income]: 'Income',
    [ActionType.Steal]: 'Steal',
    [ActionType.Tax]: 'Tax',
  } as { [key: string]: string })[actionType]);

export const getDescriptionForActionType = (actionType: ActionType) =>
  (({
    [ActionType.Assassinate]:
      'Pay three coins to force another player to discard one influence. Players may counteract this action with the Contessa.',
    [ActionType.Conversion]:
      'Pay two coins to the treasury reserve to switch the alliance of another player.',
    [ActionType.Coup]:
      'Pay seven coins to force another player to discard one influcence.',
    [ActionType.Embezzlement]:
      'Take all of the funds from the treasury reserve. Players may counteract this action with the Duke.',
    [ActionType.Examine]:
      'Force another player to reveal their influence to you, you may choose one influence for them to discard and replace.',
    [ActionType.Exchange]:
      'Draw two influence cards from the deck, return cards to the deck until you hold the same number of cards you did originally, shuffle the deck.',
    [ActionType.ForeignAid]:
      'Take two coins of income. Players may counteract this action with the Duke.',
    [ActionType.Income]: 'Take one coin of income.',
    [ActionType.Steal]:
      'Take two coins from another player. Players may counteract this action with the Ambassador or the Captain.',
    [ActionType.Tax]: 'Take three coins of income.',
  } as { [key: string]: string })[actionType].replace(/ ([^ ]*)$/, '\u00A0$1'));

export const getColorForActionType = (actionType: ActionType) =>
  (({
    [ActionType.Assassinate]: Color.Assassin,
    [ActionType.Examine]: Color.Inquisitor,
    [ActionType.Exchange]: Color.Ambassador,
    [ActionType.Steal]: Color.Captain,
    [ActionType.Tax]: Color.Duke,
  } as { [key: string]: Color | undefined })[actionType]);

export const getLabelForCounteractionType = (
  counteractionType: CounteractionType,
) =>
  (({
    [CounteractionType.AmbassadorBlockStealing]: 'Block',
    [CounteractionType.BlockAssassination]: 'Block',
    [CounteractionType.BlockForeignAid]: 'Block',
    [CounteractionType.CaptainBlockStealing]: 'Block',
    [CounteractionType.InquisitorBlockStealing]: 'Block',
    [CounteractionType.Pass]: 'Pass',
  } as { [key: string]: string })[counteractionType]);

export const getDescriptionForCounteractionType = (
  counteractionType: CounteractionType,
) =>
  (({
    [CounteractionType.AmbassadorBlockStealing]:
      'Prevent the player from stealing two coins from you.',
    [CounteractionType.BlockAssassination]:
      'Prevent yourself from discarding one influence.',
    [CounteractionType.BlockForeignAid]:
      'Prevent the player from taking two coins of income.',
    [CounteractionType.CaptainBlockStealing]:
      'Prevent the player from stealing two coins from you.',
    [CounteractionType.InquisitorBlockStealing]:
      'Prevent the player from stealing two coins from you.',
    [CounteractionType.Pass]: 'Allow the action to happen.',
  } as { [key: string]: string })[counteractionType].replace(
    / ([^ ]*)$/,
    '\u00A0$1',
  ));

export const getCharacterForCounteractionType = (
  counteractionType: string,
): Character | undefined => {
  switch (counteractionType) {
    case CounteractionType.AmbassadorBlockStealing:
      return Character.Ambassador;
    case CounteractionType.BlockAssassination:
      return Character.Contessa;
    case CounteractionType.BlockForeignAid:
      return Character.Duke;
    case CounteractionType.CaptainBlockStealing:
      return Character.Captain;
    case CounteractionType.InquisitorBlockStealing:
      return Character.Inquisitor;
  }
};

export const getColorForCounteractionType = (
  counteractionType: CounteractionType,
) =>
  (({
    [CounteractionType.AmbassadorBlockStealing]: Color.Ambassador,
    [CounteractionType.BlockAssassination]: Color.Contessa,
    [CounteractionType.BlockForeignAid]: Color.Duke,
    [CounteractionType.CaptainBlockStealing]: Color.Captain,
    [CounteractionType.InquisitorBlockStealing]: Color.Inquisitor,
  } as { [key: string]: Color | undefined })[counteractionType]);

export const getLabelForCharacter = (character: Character) =>
  (({
    [Character.Ambassador]: 'Ambassador',
    [Character.Assassin]: 'Assassin',
    [Character.Captain]: 'Captain',
    [Character.Contessa]: 'Contessa',
    [Character.Duke]: 'Duke',
    [Character.Inquisitor]: 'Inquisitor',
  } as { [key: string]: string })[character]);

export const getColorForCharacter = (character: Character) =>
  (({
    [Character.Ambassador]: Color.Ambassador,
    [Character.Assassin]: Color.Assassin,
    [Character.Captain]: Color.Captain,
    [Character.Contessa]: Color.Contessa,
    [Character.Duke]: Color.Duke,
    [Character.Inquisitor]: Color.Inquisitor,
  } as { [key: string]: Color | undefined })[character]);

export const getAssetForCharacter = (character: Character) =>
  (({
    [Character.Ambassador]: ambassador,
    [Character.Assassin]: assassin,
    [Character.Captain]: captain,
    [Character.Contessa]: contessa,
    [Character.Duke]: duke,
    [Character.Inquisitor]: inquisitor,
  } as { [key: string]: string })[character]);
