import { useCallback } from 'react';
import { useAuth } from 'reactfire';

const useFirebaseFunctionAsync = <
  Request extends { [key: string]: any; idToken?: never },
  Response
>(
  name: string,
): ((request: Request) => Promise<Response>) => {
  const auth = useAuth();

  const f = useCallback(
    async (request: Request) => {
      const idToken = await auth.currentUser?.getIdToken();
      return fetch(`/api/${name}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ idToken, ...request }),
      }).then((response) => response.json());
    },
    [auth, name],
  );

  return f;
};

export default useFirebaseFunctionAsync;
