import React from 'react';

import useCurrentTurnProperty from '../hooks/useCurrentTurnProperty';
import useCurrentTurnRef from '../hooks/useCurrentTurnRef';
import useDrawCards from '../hooks/useDrawCards';
import usePlayerProperty from '../hooks/usePlayerProperty';
import usePlayerRef from '../hooks/usePlayerRef';
import useReturnCards from '../hooks/useReturnCards';
import useUserId from '../hooks/useUserId';
import { Card as CardType } from '../shared/firebaseTypes';
import { ActionType, Character } from '../shared/types';
import {
  getCharacterHasActionType,
  getHasValue,
  getLabelForCharacter,
} from '../utils';
import ActionChallengeResolution from './ActionChallengeResolution';
import Card from './Card';
import EndTurn from './EndTurn';
import Flexbox from './Flexbox';
import Message from './Message';
import MessageButton from './MessageButton';
import MessageContainer from './MessageContainer';
import PendingMessage from './PendingMessage';
import PlayerName from './PlayerName';

type Props = {
  hasCounteractions: boolean;
};

const ActionChallengeResponse: React.FC<Props> = ({ hasCounteractions }) => {
  // Firebase data
  const userId = useUserId();
  const action = useCurrentTurnProperty('action');
  const actionChallengeResponse = useCurrentTurnProperty(
    'actionChallengeResponse',
  );
  const currentTurnPlayerUserId = useCurrentTurnProperty('playerUserId');
  const influenceCards = usePlayerProperty(userId, 'influenceCards');

  // Firebase actions
  const drawCards = useDrawCards();
  const returnCards = useReturnCards();

  // Firebase refs
  const currentTurnRef = useCurrentTurnRef();
  const playerRef = usePlayerRef(userId);

  // Derrived data
  const actionType = action?.type as ActionType;
  const characterHasActionType = getCharacterHasActionType(
    actionChallengeResponse as Character,
    actionType,
  );
  const hasActionChallengeResponse = getHasValue(actionChallengeResponse);
  const isCurrentTurnPlayer = currentTurnPlayerUserId === userId;

  if (hasActionChallengeResponse) {
    return (
      <>
        <MessageContainer key="responded" userId={currentTurnPlayerUserId}>
          <Flexbox flexDirection="row" width="100%">
            <Message>
              <span>
                <strong>
                  <PlayerName userId={currentTurnPlayerUserId} />
                </strong>{' '}
                revealed{' '}
                <strong>
                  {getLabelForCharacter(actionChallengeResponse as Character)}
                </strong>
              </span>
            </Message>
          </Flexbox>
          <Card
            character={actionChallengeResponse as Character}
            isRevealed={!characterHasActionType}
          />
        </MessageContainer>
        {characterHasActionType ? (
          <ActionChallengeResolution hasCounteractions={hasCounteractions} />
        ) : (
          <EndTurn />
        )}
      </>
    );
  }

  if (isCurrentTurnPlayer) {
    return (
      <MessageContainer key="responding" userId={userId}>
        <Flexbox flexDirection="row" width="100%">
          <Message>
            <span>
              <strong>
                <PlayerName userId={userId} />
              </strong>{' '}
              revealed…
            </span>
          </Message>
        </Flexbox>
        {Object.entries(influenceCards || {}).map(
          ([key, card]: [string, CardType], i) =>
            !card.isRevealed ? (
              <MessageButton
                key={i}
                onClick={() => {
                  currentTurnRef
                    .child('actionChallengeResponse')
                    .set(card.character);
                  if (
                    getCharacterHasActionType(
                      card.character as Character,
                      actionType,
                    )
                  ) {
                    returnCards([card])
                      .then(() => drawCards(1))
                      .then(([card]) =>
                        playerRef.child('influenceCards').child(key).set(card),
                      );
                  } else {
                    playerRef
                      .child('influenceCards')
                      .child(key)
                      .set({
                        ...card,
                        isRevealed: true,
                      });
                  }
                }}>
                {getLabelForCharacter(card.character as Character)}
              </MessageButton>
            ) : null,
        )}
      </MessageContainer>
    );
  }

  return (
    <MessageContainer userId={currentTurnPlayerUserId}>
      <PendingMessage />
    </MessageContainer>
  );
};

export default ActionChallengeResponse;
