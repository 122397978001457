import { motion } from 'framer-motion';
import styled, { CSSObject } from 'styled-components';

import { absoluteFill } from '../constants';

type Props = {
  alignItemsHorizontal?: CSSObject['alignItems'] | CSSObject['justifyContent'];
  alignItemsVertical?: CSSObject['alignItems'] | CSSObject['justifyContent'];
  columnGap?: CSSObject['marginRight'];
  flexDirection?: CSSObject['flexDirection'];
  height?: CSSObject['height'];
  isAbsoluteFill?: boolean;
  isFlexGrow?: boolean;
  isFlexWrap?: boolean;
  margin?: CSSObject['margin'];
  padding?: CSSObject['padding'];
  rowGap?: CSSObject['marginRight'];
  width?: CSSObject['width'];
};

const Flexbox = styled(motion.div).attrs(({ animate = true }) => ({
  animate,
}))<Props>`
  ${({
    alignItemsHorizontal,
    alignItemsVertical,
    flexDirection = 'column',
    height,
    isFlexGrow,
    isFlexWrap,
    margin,
    padding,
    width,
  }) => {
    const style: CSSObject = {
      flexDirection,
      flexWrap: isFlexWrap ? 'wrap' : 'nowrap',
      height,
      margin,
      padding,
      width,
    };
    if (isFlexGrow) {
      style.flexBasis = 0;
      style.flexGrow = 1;
    }
    if (flexDirection.includes('column')) {
      style.alignContent = alignItemsHorizontal;
      style.alignItems = alignItemsHorizontal;
      style.justifyContent = alignItemsVertical;
    } else {
      style.alignContent = alignItemsVertical;
      style.alignItems = alignItemsVertical;
      style.justifyContent = alignItemsHorizontal;
    }
    return style;
  }}
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  position: relative;
  ${({ isAbsoluteFill }) => ({
    ...(isAbsoluteFill ? absoluteFill : {}),
  })}
  & > * {
    ${({ columnGap, flexDirection = 'column', isFlexWrap, rowGap }) => {
      const style: CSSObject = {};
      if ((flexDirection.includes('column') || isFlexWrap) && columnGap) {
        style.marginBottom = columnGap;
      }
      if ((flexDirection.includes('row') || isFlexWrap) && rowGap) {
        style.marginRight = rowGap;
      }
      return style;
    }}
    &:last-child {
      ${({ columnGap, flexDirection = 'column', isFlexWrap, rowGap }) => {
        const style: CSSObject = {};
        if (flexDirection.includes('column') && columnGap && !isFlexWrap) {
          style.marginBottom = 0;
        }
        if (flexDirection.includes('row') && rowGap && !isFlexWrap) {
          style.marginRight = 0;
        }
        return style;
      }}
    }
  }
`;

export default Flexbox;
