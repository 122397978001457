import styled from 'styled-components';

import { absoluteFill, Color } from '../constants';
import HeadlineText from './HeadlineText';

type Props = {
  isDisabled?: boolean;
};

const Button = styled(HeadlineText).attrs(() => ({
  whileTap: { scale: 0.95 },
}))<Props>`
  ${({ isDisabled }) =>
    isDisabled
      ? { color: Color.SecondaryText, pointerEvents: 'none' }
      : { color: Color.PrimaryText }}
  align-items: center;
  background-color: ${Color.WashDeemphasized};
  border-radius: 6px;
  height: 44px;
  justify-content: center;
  overflow: hidden;
  padding: 0 16px;
  &::after {
    ${absoluteFill}
    background-color: rgba(0, 0, 0, 0.15);
    content: '';
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.1s ease-in-out;
  }
  &:active {
    &::after {
      opacity: 1;
    }
  }
`;

export default Button;
