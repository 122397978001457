import styled from 'styled-components';

import { Color } from '../../constants';
import Flexbox from '../Flexbox';
import logo from './assets/logo.png';

const Logo = styled(Flexbox)`
  background-color: ${Color.PrimaryText};
  height: 36px;
  mask-image: url(${logo});
  mask-position: left 8px;
  mask-repeat: no-repeat;
  mask-size: 100%;
  width: 108px;
`;

export default Logo;
