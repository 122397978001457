import React, { useEffect, useState } from 'react';

import { Spacing } from '../constants';
import useActionResolutionEffect from '../hooks/useActionResolutionEffect';
import useActivePlayersByUserId from '../hooks/useActivePlayersByUserId';
import useCurrentTurnProperty from '../hooks/useCurrentTurnProperty';
import useCurrentTurnRef from '../hooks/useCurrentTurnRef';
import useDrawCards from '../hooks/useDrawCards';
import usePlayerProperty from '../hooks/usePlayerProperty';
import usePlayerRef from '../hooks/usePlayerRef';
import useReturnCards from '../hooks/useReturnCards';
import useUserId from '../hooks/useUserId';
import { Card as CardType } from '../shared/firebaseTypes';
import { ActionType, Character } from '../shared/types';
import {
  getHasValue,
  getLabelForCharacter,
  getNeedsResolutionForActionType,
} from '../utils';
import BodyText from './BodyText';
import Button from './Button';
import Card from './Card';
import EndTurn from './EndTurn';
import Flexbox from './Flexbox';
import Message from './Message';
import MessageButton from './MessageButton';
import MessageContainer from './MessageContainer';
import Modal from './Modal';
import PendingMessage from './PendingMessage';
import PlayerName from './PlayerName';

const ActionResolution: React.FC = () => {
  // Firebase data
  const userId = useUserId();
  const action = useCurrentTurnProperty('action');
  const actionResolution = useCurrentTurnProperty('actionResolution');
  const activePlayersByUserId = useActivePlayersByUserId();
  const currentTurnPlayerUserId = useCurrentTurnProperty('playerUserId');
  const exchangeActionCards = useCurrentTurnProperty('exchangeActionCards');
  const influenceCards = usePlayerProperty(userId, 'influenceCards');

  // Firebase refs
  const currentTurnRef = useCurrentTurnRef();
  const playerRef = usePlayerRef(userId);

  // Firebase actions
  const actionResolutionEffect = useActionResolutionEffect();
  const drawCards = useDrawCards();
  const returnCards = useReturnCards();

  // Derrived data
  const hasActionResolution = getHasValue(actionResolution);
  const needsActionResolution = getNeedsResolutionForActionType(
    action?.type as ActionType,
  );
  const isCurrentTurnPlayer = currentTurnPlayerUserId === userId;
  const isTargetPlayer = action?.targetUserId === userId;
  const exchangeActionCardValues = Object.values(exchangeActionCards || {});
  const allExchangeActionCards = [
    ...Object.values(influenceCards || {}).filter((card) => !card.isRevealed),
    ...exchangeActionCardValues,
  ];

  // Local state
  const [
    selectedExchangeActionCardIndexes,
    setSelectedExchangeActionCardIndexes,
  ] = useState<Array<number>>([]);

  // Effects
  useEffect(() => {
    if (
      isCurrentTurnPlayer &&
      action?.type === ActionType.Exchange &&
      !exchangeActionCardValues.length
    ) {
      drawCards(2).then((cards) =>
        currentTurnRef.child('exchangeActionCards').set(cards),
      );
    }
  }, [
    action,
    currentTurnRef,
    drawCards,
    exchangeActionCardValues.length,
    exchangeActionCards,
    isCurrentTurnPlayer,
  ]);

  useEffect(() => {
    if (isCurrentTurnPlayer) {
      actionResolutionEffect();
    }
  }, [actionResolutionEffect, isCurrentTurnPlayer]);

  if (
    !needsActionResolution ||
    (!hasActionResolution &&
      action?.targetUserId &&
      !activePlayersByUserId[action?.targetUserId])
  ) {
    return <EndTurn />;
  }

  if (
    action?.type === ActionType.Assassinate ||
    action?.type === ActionType.Coup
  ) {
    if (hasActionResolution) {
      return (
        <>
          <MessageContainer
            key="resolved"
            userId={action?.targetUserId as string}>
            <Flexbox flexDirection="row" width="100%">
              <Message>
                <span>
                  <strong>
                    <PlayerName userId={action?.targetUserId as string} />
                  </strong>{' '}
                  revealed{' '}
                  <strong>
                    {getLabelForCharacter(actionResolution as Character)}
                  </strong>
                </span>
              </Message>
            </Flexbox>
            <Card character={actionResolution as Character} isRevealed />
          </MessageContainer>
          <EndTurn />
        </>
      );
    }

    if (isTargetPlayer) {
      return (
        <MessageContainer key="resolving" userId={userId}>
          <Flexbox flexDirection="row" width="100%">
            <Message>
              <span>
                <strong>
                  <PlayerName userId={userId} />
                </strong>{' '}
                revealed…
              </span>
            </Message>
          </Flexbox>
          {Object.entries(influenceCards || {}).map(
            ([key, card]: [string, CardType], i) =>
              !card.isRevealed ? (
                <MessageButton
                  key={i}
                  onClick={() => {
                    currentTurnRef
                      .child('actionResolution')
                      .set(card.character);
                    playerRef
                      .child('influenceCards')
                      .child(key)
                      .set({
                        ...card,
                        isRevealed: true,
                      });
                  }}>
                  {getLabelForCharacter(card.character as Character)}
                </MessageButton>
              ) : null,
          )}
        </MessageContainer>
      );
    }

    return (
      <MessageContainer userId={action?.targetUserId as string}>
        <PendingMessage />
      </MessageContainer>
    );
  }

  if (action?.type === ActionType.Exchange) {
    if (hasActionResolution) {
      return (
        <>
          <MessageContainer key="resolution" userId={currentTurnPlayerUserId}>
            <Message>
              <span>
                <strong>
                  <PlayerName userId={currentTurnPlayerUserId} />
                </strong>{' '}
                exchanged influence with the court
              </span>
            </Message>
          </MessageContainer>
          <EndTurn />
        </>
      );
    }

    if (isCurrentTurnPlayer) {
      if (exchangeActionCardValues.length === 0) {
        return null;
      }

      return (
        <>
          <Modal>
            <Flexbox
              alignItemsHorizontal="center"
              alignItemsVertical="center"
              isFlexGrow>
              <Flexbox
                alignItemsHorizontal="center"
                flexDirection="row"
                isFlexWrap
                style={{ maxWidth: 264 }}>
                {allExchangeActionCards.map((card, i) => (
                  <Flexbox
                    key={i}
                    alignItemsHorizontal="center"
                    animate={
                      selectedExchangeActionCardIndexes.length &&
                      !selectedExchangeActionCardIndexes.includes(i)
                        ? { opacity: 0.5, scale: 0.98, translateY: 8 }
                        : { opacity: 1, scale: 1, translateY: 0 }
                    }
                    columnGap={Spacing.Small}
                    onClick={() =>
                      selectedExchangeActionCardIndexes.includes(i)
                        ? setSelectedExchangeActionCardIndexes(
                            selectedExchangeActionCardIndexes.filter(
                              (index) => index !== i,
                            ),
                          )
                        : selectedExchangeActionCardIndexes.length <
                            allExchangeActionCards.length - 2 &&
                          setSelectedExchangeActionCardIndexes([
                            ...selectedExchangeActionCardIndexes,
                            i,
                          ])
                    }
                    margin={Spacing.Medium}
                    transition={{ duration: 0.25 }}>
                    <Card
                      isSelected={
                        !selectedExchangeActionCardIndexes.length ||
                        selectedExchangeActionCardIndexes.includes(i)
                      }
                      {...card}
                    />
                    <BodyText>{getLabelForCharacter(card.character)}</BodyText>
                  </Flexbox>
                ))}
              </Flexbox>
              <Button
                isDisabled={
                  selectedExchangeActionCardIndexes.length !==
                  allExchangeActionCards.length - 2
                }
                onClick={() => {
                  const selectedCards: Array<CardType> = allExchangeActionCards.filter(
                    (card, i) => selectedExchangeActionCardIndexes.includes(i),
                  );
                  const returnedCards: Array<CardType> = allExchangeActionCards.filter(
                    (card, i) => !selectedExchangeActionCardIndexes.includes(i),
                  );

                  if (selectedCards.length === 2) {
                    currentTurnRef.child('actionResolution').set(true);
                    playerRef.child('influenceCards').set({
                      left: selectedCards[0],
                      right: selectedCards[1],
                    });
                    returnCards(returnedCards);
                  } else if (selectedCards.length === 1) {
                    const key = Object.entries(influenceCards || {})
                      .filter(([key, card]) => !card.isRevealed)
                      .map(([key]) => key)[0];
                    currentTurnRef.child('actionResolution').set(true);
                    playerRef
                      .child('influenceCards')
                      .update({ [key]: selectedCards[0] });
                    returnCards(returnedCards);
                  }
                }}>
                Exchange
              </Button>
            </Flexbox>
          </Modal>
        </>
      );
    }

    return (
      <MessageContainer key="pending" userId={currentTurnPlayerUserId}>
        <PendingMessage />
      </MessageContainer>
    );
  }

  return null;
};

export default ActionResolution;
