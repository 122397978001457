export type Id = string;

export enum Allegiance {
  Loyalist = 'LOYALIST',
  Reformist = 'REFORMIST',
}

export enum Character {
  Ambassador = 'AMBASSADOR',
  Assassin = 'ASSASSIN',
  Captain = 'CAPTAIN',
  Contessa = 'CONTESSA',
  Duke = 'DUKE',
  Inquisitor = 'INQUISITOR',
}

export enum ActionType {
  Assassinate = 'ASSASSINATE',
  Conversion = 'CONVERSION',
  Coup = 'COUP',
  Embezzlement = 'EMBEZZLEMENT',
  Examine = 'EXAMINE',
  Exchange = 'EXCHANGE',
  ForeignAid = 'FOREIGN_AID',
  Income = 'INCOME',
  Steal = 'STEAL',
  Tax = 'TAX',
}

export enum CounteractionType {
  Pass = 'PASS',
  AmbassadorBlockStealing = 'AMBASSADOR_BLOCK_STEALING',
  CaptainBlockStealing = 'CAPTAIN_BLOCK_STEALING',
  BlockForeignAid = 'BLOCK_FOREIGN_AID',
  BlockAssassination = 'BLOCK_ASSASSINATION',
  InquisitorBlockStealing = 'INQUISITOR_BLOCK_STEALING',
}

export enum Response {
  Challenge = 'CHALLENGE',
  Pass = 'PASS',
}
