import { useCallback } from 'react';

import { ActionType } from '../shared/types';
import { getHasValue } from '../utils';
import useCurrentTurnProperty from './useCurrentTurnProperty';
import useCurrentTurnRef from './useCurrentTurnRef';
import usePlayerRef from './usePlayerRef';

const useActionResolutionEffect = () => {
  const currentTurnRef = useCurrentTurnRef();
  const action = useCurrentTurnProperty('action');
  const currentTurnPlayerUserId = useCurrentTurnProperty('playerUserId');
  const currentTurnPlayerRef = usePlayerRef(currentTurnPlayerUserId);
  const targetPlayerRef = usePlayerRef(
    action?.targetUserId || currentTurnPlayerUserId,
  );
  return useCallback(async () => {
    const actionResolution = (
      await currentTurnRef.child('actionResolution').once('value')
    ).val();
    const hasActionResolution = getHasValue(actionResolution);
    if (hasActionResolution) {
      return;
    }

    const currentTurnPlayerCoins = (
      await currentTurnPlayerRef.child('coins').once('value')
    ).val();
    const targetPlayerCoins = (
      await targetPlayerRef.child('coins').once('value')
    ).val();

    switch (action?.type) {
      case ActionType.Conversion:
        currentTurnRef.child('actionResolution').set(true);
        currentTurnPlayerRef.child('coins').set(currentTurnPlayerCoins - 2);
        break;
      case ActionType.Coup:
        currentTurnPlayerRef.child('coins').set(currentTurnPlayerCoins - 7);
        break;
      case ActionType.Embezzlement:
        currentTurnRef.child('actionResolution').set(true);
        break;
      case ActionType.ForeignAid:
        currentTurnRef.child('actionResolution').set(true);
        currentTurnPlayerRef
          .child('coins')
          .set((currentTurnPlayerCoins as number) + 2);
        break;
      case ActionType.Income:
        currentTurnRef.child('actionResolution').set(true);
        currentTurnPlayerRef.child('coins').set(currentTurnPlayerCoins + 1);
        break;
      case ActionType.Steal:
        currentTurnRef.child('actionResolution').set(true);
        currentTurnPlayerRef
          .child('coins')
          .set(
            (currentTurnPlayerCoins as number) +
              Math.min(targetPlayerCoins as number, 2),
          );
        targetPlayerRef
          .child('coins')
          .set(Math.max((targetPlayerCoins as number) - 2, 0));
        break;
      case ActionType.Tax:
        currentTurnRef.child('actionResolution').set(true);
        currentTurnPlayerRef
          .child('coins')
          .set((currentTurnPlayerCoins as number) + 3);
        break;
    }
  }, [currentTurnRef, action, currentTurnPlayerRef, targetPlayerRef]);
};

export default useActionResolutionEffect;
