import { useCallback } from 'react';

import { Card, Game } from '../shared/firebaseTypes';
import useGameRef from './useGameRef';

const useDrawCards = () => {
  const gameRef = useGameRef();

  const drawCards = useCallback(
    async (count: number): Promise<Array<Card>> => {
      const courtDeckCards: Game['courtDeckCards'] = (
        await gameRef.child('courtDeckCards').once('value')
      ).val();
      const nextCourtDeckCards = [...Object.values(courtDeckCards || {})];
      const cards = nextCourtDeckCards.splice(0, count);
      gameRef.child('courtDeckCards').set(nextCourtDeckCards);
      return cards;
    },
    [gameRef],
  );

  return drawCards;
};

export default useDrawCards;
