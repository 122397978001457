import { detect } from 'detect-browser';
import { AnimatePresence } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';

import { Spacing } from '../constants';
import useActivePlayersByUserId from '../hooks/useActivePlayersByUserId';
import useGameInviteCode from '../hooks/useGameInviteCode';
import useGameProperty from '../hooks/useGameProperty';
import usePlayerProperty from '../hooks/usePlayerProperty';
import useStartGame from '../hooks/useStartGame';
import useUserId from '../hooks/useUserId';
import { getLabelForCharacter } from '../utils';
import Action from './Action';
import BodyText from './BodyText';
import Button from './Button';
import Card from './Card';
import Flexbox from './Flexbox';
import Logo from './Logo';
import Modal from './Modal';
import PendingAvatar from './PendingAvatar';
import PlayerAvatar from './PlayerAvatar';
import PlayerCoins from './PlayerCoins';
import PlayerInfluenceCards from './PlayerInfluenceCards';
import TurnOrder from './TurnOrder';

const browser = detect();

const GameRoute: React.FC = () => {
  const userId = useUserId();
  const hasStarted = useGameProperty('hasStarted') === true;
  const [isTurnOrderExpanded, setIsTurnOrderExpanded] = useState(false);
  const hostPlayerUserId = useGameProperty('hostPlayerUserId');
  const isHostPlayer = userId === hostPlayerUserId;
  const gameInviteCode = useGameInviteCode();
  const startGame = useStartGame();
  const playersByUserId = useGameProperty('playersByUserId');
  const activePlayersByUserId = useActivePlayersByUserId();
  const hasWinner = Object.keys(activePlayersByUserId).length === 1;
  const [hasTopBar, setHasTopBar] = useState(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const currentTurn = useGameProperty('currentTurn');
  const [areInfluenceCardsVisible, setAreInfluenceCardsVisible] = useState(
    false,
  );
  const influenceCards = usePlayerProperty(userId, 'influenceCards');

  useEffect(() => {
    if (ref.current) {
      if (browser && browser.name) {
        if (browser.name === 'ios') {
          setHasTopBar(
            ref.current.scrollHeight > ref.current.offsetHeight &&
              -ref.current.scrollTop + ref.current.offsetHeight <
                ref.current.scrollHeight,
          );
        } else {
          setHasTopBar(
            ref.current.scrollHeight > ref.current.offsetHeight &&
              ref.current.scrollTop !== 0,
          );
        }
      }
    }
  }, [currentTurn]);

  return (
    <Flexbox animate={{}} isAbsoluteFill style={{ overflow: 'hidden' }}>
      <Flexbox
        animate={hasStarted ? true : {}}
        columnGap={Spacing.Medium}
        padding={`13px ${Spacing.Medium}px`}
        style={{
          minHeight: 62,
          position: 'absolute',
          width: '100%',
          zIndex: 3,
        }}>
        <AnimatePresence>
          {!hasStarted && (
            <Flexbox
              exit={{ height: 0, marginBottom: 0, opacity: 0 }}
              initial={{ height: 'auto', opacity: 1 }}>
              <Logo />
            </Flexbox>
          )}
          {(hasTopBar || isTurnOrderExpanded) && (
            <Flexbox
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0 }}
              isAbsoluteFill
              style={{
                backdropFilter: 'blur(10px)',
                backgroundColor: 'rgba(252, 252, 255, 0.8)',
                boxShadow: '0 1px 0 rgba(0, 0, 0, 0.1)',
                WebkitBackdropFilter: 'blur(10px)',
              }}
              transition={{ duration: 0.1 }}
            />
          )}
        </AnimatePresence>
        <Flexbox animate={{}} columnGap={Spacing.Small}>
          {gameInviteCode && (
            <TurnOrder
              isExpanded={isTurnOrderExpanded}
              setIsExpanded={setIsTurnOrderExpanded}
            />
          )}
          <AnimatePresence>
            {gameInviteCode && !hasStarted && (
              <Flexbox
                alignItemsVertical="center"
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                flexDirection="row"
                initial={{ opacity: 0 }}
                rowGap={Spacing.Small}>
                <PendingAvatar />
                <BodyText>
                  <span>
                    Join this game with code <strong>{gameInviteCode}</strong>
                  </span>
                </BodyText>
              </Flexbox>
            )}
          </AnimatePresence>
        </Flexbox>
        <AnimatePresence>
          {gameInviteCode && !hasStarted && (
            <Flexbox
              animate={{ opacity: 1, translateY: 0 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0, translateY: 20 }}>
              {isHostPlayer && (
                <Button
                  isDisabled={Object.keys(playersByUserId || {}).length < 2}
                  onClick={() => startGame()}>
                  Everybody’s in
                </Button>
              )}
            </Flexbox>
          )}
        </AnimatePresence>
      </Flexbox>
      {gameInviteCode && hasStarted && (
        <>
          <Flexbox animate={{}} isAbsoluteFill>
            <Flexbox
              animate={{}}
              flexDirection="column-reverse"
              isAbsoluteFill
              onScroll={(e) => {
                if (browser && browser.name) {
                  if (browser.name === 'ios') {
                    setHasTopBar(
                      e.currentTarget.scrollHeight >
                        e.currentTarget.offsetHeight &&
                        -e.currentTarget.scrollTop +
                          e.currentTarget.offsetHeight <
                          e.currentTarget.scrollHeight,
                    );
                  } else {
                    setHasTopBar(
                      e.currentTarget.scrollHeight >
                        e.currentTarget.offsetHeight &&
                        e.currentTarget.scrollTop !== 0,
                    );
                  }
                }
              }}
              ref={(node) => {
                ref.current = node;
                if (ref.current && browser && browser.name) {
                  if (browser.name === 'ios') {
                    setHasTopBar(
                      ref.current.scrollHeight > ref.current.offsetHeight &&
                        -ref.current.scrollTop + ref.current.offsetHeight <
                          ref.current.scrollHeight,
                    );
                  } else {
                    setHasTopBar(
                      ref.current.scrollHeight > ref.current.offsetHeight &&
                        ref.current.scrollTop !== 0,
                    );
                  }
                }
              }}
              style={{ overflowY: 'scroll' }}>
              <div style={{ willChange: 'transform' }}>
                <Flexbox height={62} />
                <Flexbox
                  alignItemsVertical="flex-end"
                  animate={{}}
                  isFlexGrow
                  columnGap={Spacing.Large}
                  padding={Spacing.Medium}>
                  <Action />
                </Flexbox>
                <Flexbox height={62} />
              </div>
            </Flexbox>
            <Flexbox
              padding={Spacing.Medium}
              style={{
                bottom: 0,
                position: 'absolute',
                width: '100%',
                zIndex: 3,
              }}>
              <Flexbox
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                initial={{ opacity: 0 }}
                isAbsoluteFill
                style={{
                  backdropFilter: 'blur(10px)',
                  backgroundColor: 'rgba(252, 252, 255, 0.8)',
                  boxShadow: '0 -1px 0 rgba(0, 0, 0, 0.1)',
                  WebkitBackdropFilter: 'blur(10px)',
                }}
                transition={{ duration: 0.1 }}
              />
              <Flexbox
                flexDirection="row"
                alignItemsHorizontal="space-between"
                alignItemsVertical="center"
                animate={{ opacity: 1, translateY: 0 }}
                initial={{ opacity: 0, translateY: 20 }}>
                <BodyText>
                  <PlayerCoins userId={userId} /> coins
                </BodyText>
                <Flexbox
                  alignItemsVertical="center"
                  flexDirection="row"
                  onClick={() => setAreInfluenceCardsVisible(true)}
                  rowGap={Spacing.Small}
                  whileTap={{ opacity: 0.5, scale: 0.95 }}>
                  <PlayerInfluenceCards isVisible userId={userId} />
                </Flexbox>
              </Flexbox>
            </Flexbox>
          </Flexbox>
          <AnimatePresence>
            {hasWinner && (
              <Modal>
                <Flexbox
                  alignItemsHorizontal="center"
                  alignItemsVertical="center"
                  columnGap={Spacing.Small}
                  isFlexGrow>
                  <PlayerAvatar
                    size={100}
                    userId={Object.keys(activePlayersByUserId)[0]}
                  />
                  <BodyText>
                    {Object.values(activePlayersByUserId)[0].name} won!
                  </BodyText>
                  {isHostPlayer && (
                    <Button onClick={() => startGame()}>Play again</Button>
                  )}
                </Flexbox>
              </Modal>
            )}
            {areInfluenceCardsVisible && (
              <Modal>
                <Flexbox
                  alignItemsHorizontal="center"
                  alignItemsVertical="center"
                  isFlexGrow
                  onClick={() => setAreInfluenceCardsVisible(false)}>
                  <Flexbox
                    alignItemsHorizontal="center"
                    flexDirection="row"
                    isFlexWrap
                    style={{ maxWidth: 264 }}>
                    {Object.values(influenceCards || {}).map((card, i) => (
                      <Flexbox
                        key={i}
                        alignItemsHorizontal="center"
                        columnGap={Spacing.Small}
                        margin={Spacing.Medium}>
                        <Card {...card} />
                        <BodyText>
                          {getLabelForCharacter(card.character)}
                        </BodyText>
                      </Flexbox>
                    ))}
                  </Flexbox>
                </Flexbox>
              </Modal>
            )}
          </AnimatePresence>
        </>
      )}
    </Flexbox>
  );
};

export default GameRoute;
