import React from 'react';

import usePlayerProperty from '../hooks/usePlayerProperty';

type Props = {
  userId: string;
};

const PlayerCoins: React.FC<Props> = ({ userId }) => (
  <>{usePlayerProperty(userId, 'coins')}</>
);

export default PlayerCoins;
