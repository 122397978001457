import { Game } from '../shared/firebaseTypes';
import useGameProperty from './useGameProperty';

const useActivePlayersByUserId = (): Game['playersByUserId'] => {
  const playersByUserId = useGameProperty('playersByUserId');
  return Object.entries(playersByUserId)
    .filter(
      ([key, player]) =>
        !player.influenceCards?.left.isRevealed ||
        !player.influenceCards?.right.isRevealed,
    )
    .reduce(
      (activePlayersByUserId, [key, player]) => ({
        ...activePlayersByUserId,
        [key]: player,
      }),
      {},
    );
};

export default useActivePlayersByUserId;
