import styled from 'styled-components';

import { Color, Spacing } from '../constants';
import BodyText from './BodyText';

type Props = {
  backgroundColor?: string;
  isCurrentTurnPlayer?: boolean;
};

const Message = styled(BodyText)<Props>`
  ${({ backgroundColor }) =>
    backgroundColor
      ? { backgroundColor, color: Color.WhiteText }
      : { backgroundColor: Color.WashDeemphasized }}
  flex-shrink: 1;
  padding: ${Spacing.Small}px ${Spacing.Medium}px;
  & strong {
    font-weight: 700;
  }
`;

export default Message;
