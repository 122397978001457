import { useMemo } from 'react';

import useGameRef from './useGameRef';

const usePlayerRef = (userId: string) => {
  const gameRef = useGameRef();
  return useMemo(() => gameRef.child('playersByUserId').child(userId), [
    gameRef,
    userId,
  ]);
};

export default usePlayerRef;
